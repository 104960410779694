/*eslint no-dupe-class-members: "off"*/
import { isFile, isDocument, priceToNumber } from '@utils/common';

import { DOCUMENT_TYPE_CODE } from "@configs/documents";
import { INVEST_TYPES, LOAN_PUPRPOSES } from '@configs/investProject';
import {FLOATING_RATE_SCHEMES, RATE_TYPES_CODES} from "@services/projects/project";

class AbstractProjectBuilder {
	_buildAction;

	_project = {};

	constructor({ buildAction }) {
		this._buildAction = buildAction;
	}

	investType(type, project) {
		switch (type) {
		case INVEST_TYPES.STOCK:
			this._stock(project);
			return this;

		case INVEST_TYPES.LOAN:
			this._loan(project);
			return this;

		case INVEST_TYPES.CONVERTIBLE_LOAN:
			this._convertibleLoan(project);
			return this;

		case INVEST_TYPES.UCP:
			this._ucp(project);
			return this;

		default:
			throw new TypeError('Unknown investment type.');
		}
	}

	_stock() {
		this._project.type = INVEST_TYPES.UCP;
		return this;
	}

	_loan() {
		this._project.type = INVEST_TYPES.LOAN;
		return this;
	}

	_convertibleLoan() {
		this._project.type = INVEST_TYPES.CONVERTIBLE_LOAN;
		return this;
	}

	_ucp() {
		this._project.type = INVEST_TYPES.UCP;
		return this;
	}

	_projectInfo(project) {
		if (isFile(project.advertisement))
			this._project.advertisement = project.advertisement;

		if (isFile(project.banner)) this._project.banner = project.banner;
		if (isFile(project.bannerSmall)) this._project.bannerSmall = project.bannerSmall;

		if (isFile(project.logo)) this._project.logo = project.logo;

		if (project.media instanceof Array && project.media.length > 0) {
			this._project.media = project.media.filter((file) =>
				isFile(file) ? file : undefined
			);
		}

		this._project.brief = project.brief;
		this._project.details = project.details;
		this._project.name = project.name;
		this._project.businessTypeCodes = project.businessTypeCodes;

		return this;
	}

	build(buildAction) {
		this._buildAction = buildAction ?? this._buildAction;
		return this._buildAction(this._project);
	}
}

export class InvestProjectBuilder extends AbstractProjectBuilder {
	constructor({ buildAction }) {
		super({ buildAction });
	}
	
	projectInfo(project) {
		this._projectInfo(project);
		if (project.id) {
			this._project.id = project.id;
		}
		this._project.participantId = project.participantId;
		return this;
	}

	documents(documents) {
		if (!Array.isArray(documents)) {
			return this;
		}
		
		this._project.documents = [];

		for (const doc of documents) {
			if (isDocument(doc)) {
				if (this._project.loanPurposeCode !== LOAN_PUPRPOSES.INVESTMENT_LOAN && doc.typeCode === DOCUMENT_TYPE_CODE.BUSINESS_PLAN) {
					continue;
				}

				this._project.documents.push(doc);
			}
		}

		return this;
	}

	team(team) {
		if (typeof team !== 'object' || team == null) {
			return this;
		}

		this._project.team = {};
		this._project.team.description = team.description;

		if (team.members instanceof Array && team.members.length > 0) {
			this._project.team.members = team.members.map((member) => {
				const m = {};

				if (isFile(member.photo)) m.photo = member.photo;

				m.teamMemberId = member.teamMemberId || null;
				m.about = member.about;
				m.name = member.name;
				m.role = member.role;

				return m;
			});
		}

		return this;
	}

	disclosureInformation(info) {
		if (info == null) return this;

		this._project.disclosureInformation = {};

		for (const key in info) {
			this._project.disclosureInformation[key] = info[key];
		}

		return this;
	}

	founderDetails(details) {
		if (details == null) return this;

		this._project.founderDetails = {};
		this._project.founderDetails.influenceFacts = details.influenceFacts;
		this._project.founderDetails.raisedSum = priceToNumber(
			details.raisedSum
		);

		return this;
	}

	_ucp(project) {
		try {
			this.#setInvestTarget(project);
			this.#setInvestmentBoundaries(project);
			this.#setInvesterRights(project);
			this.#setInvestorGroupType(project);

			if (project.ucp) {
				this._project.ucp = project.ucp.map((ucp) => ({
					amount: parseInt(ucp.amount),
					description: ucp.description,
					name: ucp.name,
					price: priceToNumber(ucp.price),
					typeCode: ucp.typeCode,
					maturityTerm: ucp.maturityTerm,
				}));
			}

			this._project.publishTerm = project.publishTerm;
			this._project.operatorFee = project.operatorFee;
			this._project.parentId = project.parentId;

			this._project.type = INVEST_TYPES.UCP;
			return this;
		} catch (error) {
			console.warn('UCP can not be created.');
			throw error;
		}
	}

	_stock(project) {
		try {
			this.#setInvestTarget(project);
			this.#setInvestmentBoundaries(project);
			this.#setInvesterRights(project);
			this.#setInvestorGroupType(project);

			this._project.maturityCap = priceToNumber(
				project.maturityCap
			);
			this._project.placementPrice = priceToNumber(
				project.placementPrice
			);
			this._project.sharePrice = priceToNumber(project.sharePrice);

			this._project.shareRegisterNum = project.shareRegisterNum;

			this._project.stockCalcOption = project.stockCalcOption;

			this._project.proposedShare = project.proposedShare;
			this._project.proposedShareMin = project.proposedShareMin;

			this._project.sharesCount = parseInt(
				project.sharesCount
			);
			this._project.sharesCountMin = parseInt(
				project.sharesCountMin
			);

			this._project.shareTypeCode = project.shareTypeCode;
			this._project.reportQuantity = project.reportQuantity ?? 0;
			this._project.reportUnit = project.reportUnit;
			this._project.publishTerm = project.publishTerm;
			this._project.shareholdersRegistry = project.shareholdersRegistry;
			this._project.operatorFee = project.operatorFee;
			this._project.parentId = project.parentId;
			this._project.shareholders = project.shareholders;

			this._project.type = INVEST_TYPES.STOCK;
			return this;
		} catch (error) {
			console.warn('Stocks can not be created.');
			throw error;
		}
	}

	_loan(project) {
		try {
			this.#setInvestTarget(project);
			this.#setInvestmentBoundaries(project);
			this.#setInvesterRights(project);
			this.#setInvestorGroupType(project);

			this._project.rateType = project.rateType;
			this._project.interestRate = project.interestRate; // процент по займу
			this._project.term = parseInt(project.term); // срок займа
			this._project.paymentSchemeCode = project.paymentSchemeCode;
			this._project.collateralTypes = project.collateralTypes;
			this._project.publishTerm = project.publishTerm;
			this._project.operatorFee = project.operatorFee;
			this._project.parentId = project.parentId;
			this._project.loanPurposeCode = project.loanPurposeCode;
			this._project.paymentDelayCode = project.paymentDelayCode;

			if (!FLOATING_RATE_SCHEMES.includes(project.paymentSchemeCode) || !project.rateType) {
				this._project.rateType = RATE_TYPES_CODES.FIXED;
			}

			if (project.loanPurposeCode === LOAN_PUPRPOSES.GOVERMENT_CONTRACT) {
				this._project.govContract = project.govContract;
			}

			this._project.type = INVEST_TYPES.LOAN;
			return this;
		} catch (error) {
			console.warn('Loan can not be created.');
			throw error;
		}
	}

	_convertibleLoan(project) {
		try {
			this._loan(project);
			this.#setInvestTarget(project);
			this.#setInvestmentBoundaries(project);
			this.#setInvesterRights(project);
			this.#setInvestorGroupType(project);

			this._project.sharePrice = priceToNumber(project.sharePrice);

			this._project.conversionConditions = project.conversionConditions;

			this._project.collateralTypes = project.collateralTypes;
			this._project.publishTerm = project.publishTerm;
			this._project.operatorFee = project.operatorFee;
			this._project.reportUnit = project.reportUnit;
			this._project.parentId = project.parentId;
			this._project.shareholders = project.shareholders;

			this._project.shareholdersRegistry = project.shareholdersRegistry;

			this._project.type = INVEST_TYPES.CONVERTIBLE_LOAN;
			return this;
		} catch (error) {
			console.warn('Convertible loan can not be created.');
			throw new Error(error);
		}
	}

	#setInvestTarget(data) {
		this._project.maximumTarget = priceToNumber(data.maximumTarget);
		this._project.minimumTarget = priceToNumber(data.minimumTarget);

		this._project.target = this._project.maximumTarget;
	}

	#setInvestorGroupType(data) {
		this._project.investorGroupType = data.investorGroupType;
	}

	#setInvesterRights(data) {
		this._project.investorHasPreemptiveRights =
			data.investorHasPreemptiveRights;
		this._project.propertyInformationRights =
			data.propertyInformationRights;
	}

	#setInvestmentBoundaries(data) {
		this._project.maximumInvestment = priceToNumber(data.maximumInvestment);
		this._project.minimumInvestment = priceToNumber(data.minimumInvestment);
	}
}

export class PitchBuilder extends AbstractProjectBuilder {
	constructor({ buildAction }) {
		super({ buildAction });
	}

	documents(documents) {
		if (!Array.isArray(documents)) {
			return this;
		}
		
		this._project.documents = [];

		for (const doc of documents) {
			if (isDocument(doc)) {
				this._project.documents.push(doc);
			}
		}

		return this;
	}

	projectInfo(project) {
		if (project.id) {
			this._project.id = project.id;
		}
		this._projectInfo(project);
		this._project.supposedType = project.supposedType;
		this._project.businessTypeCodes = project.businessTypeCodes;
		this._project.publishTerm = project.publishTerm;
		this._project.target = priceToNumber(project.target);
		this.documents(project.documents)

		return this;
	}
}
