import {
	getChats,
	getChat,
	getAdminUserChats,
	getChatMessages,
	sendMessage,
	updateUnreads,
	getOperatorChat,
	createChatDialog, linkFileChat,
} from '@services/chats';
import { randomKey } from '@/utils/common';
import { uploadFile } from '@/services/files';

/**
 * @type {import('vuex').Module}
 */
export default {
	namespaced: true,

	state: {
		chats: [],
		initialChat: null,
		operatorChatId: null,
		currentChatMessages: [],
		currentMessage: '',
		searchValue: '',
		attachments: [],
		loading: false,
		chatListLoading: false,
		selectedChatId: null,
		messageOwner: null
	},
	actions: {
		async actionUploadAttachments({ state, commit }) {
			const res = await Promise.all(
				state.attachments.map((file) =>
					uploadFile(file.file).then((res) => ({
						...res
					})),
				),
			);

			res?.forEach((file) => linkFileChat(state.selectedChatId, file.fileId));

			commit('mutationSetAttachments', res);
			return res;
		},
		async actionFetchChats({ commit }, loading) {
			if (loading) commit('mutationChatLoading', true);
			return getChats()
				.then((res) => {
					commit('mutationCurrentChats', res);
				})
				.finally(() => {
					if (loading) commit('mutationChatLoading', false);
				});
		},
		async actionSearchChats({ state, commit }) {
			commit('mutationChatListLoading', true);
			if (state.messageOwner) {
				return getAdminUserChats(state.messageOwner, state.searchValue)
					.then((res) => {
						commit('mutationCurrentChats', res);
					})
					.finally(() => {
						commit('mutationChatListLoading', false);
					});
			} else {
				return getChats(state.searchValue)
					.then((res) => {
						commit('mutationCurrentChats', res);
					})
					.finally(() => {
						commit('mutationChatListLoading', false);
					});
			}
		},
		async actionFetchAdminUserChats({ state, commit }, userChats) {
			if (userChats.loading) commit('mutationChatLoading', true);
			return getAdminUserChats(state.messageOwner)
				.then((res) => {
					commit('mutationCurrentChats', res);
				})
				.finally(() => {
					if (userChats.loading) commit('mutationChatLoading', false);
				});
		},
		async actionGetChat({ commit }, chatId) {
			return getChat(chatId).then((res) => {
				commit('mutationInitialChat', res);
			});
		},
		async actionGetChatMessages({ state, commit }, chatId) {
			if (state.messageOwner) {
				return getChatMessages(chatId, state.messageOwner).then((res) => {
					commit('mutationCurrentChatMessages', res);
				});
			} else {
				return getChatMessages(chatId).then((res) => {
					commit('mutationCurrentChatMessages', res);
				});
			}
		},
		actionSetMessageOwner({ commit }, messageOwner) {
			commit('mutationMessageOwner', messageOwner);
		},
		actionSetSearchValue({ commit }, searchValue) {
			commit('mutationSearchValue', searchValue);
		},
		async actionGetOperatorChatId({ commit }) {
			return getOperatorChat().then((res) => {
				commit('mutationOperatorChatId', res);
			});
		},
		async actionSendCurrentMessage({ state }) {
			return sendMessage(
				state.selectedChatId,
				state.currentMessage,
				state.attachments,
			);
		},
		async actionUpdateUnreads({ commit }, chatId) {
			commit('mutationCurrentChatUnreads', 0);
			return updateUnreads(chatId, 1000);
		},
		async actionCreateChatDialog({ state, commit }, data) {
			commit('mutationChatLoading', true);
			return createChatDialog(data.projectId, data.participantsIdList)
				.then((chatId) => {
					sendMessage(chatId, state.currentMessage)
						.then(() => {
							commit('mutationChatLoading', false);
						})
						.finally(() => {
							commit('mutationChatLoading', false);
						});
				})
				.finally(() => {
					commit('mutationChatLoading', false);
				});
		},
		async actionCreateEmptyChatDialog({ commit }, data) {
			commit('mutationChatLoading', true);
			return createChatDialog(data.projectId, data.participantsIdList)
				.finally(() => {
					commit('mutationChatLoading', false);
				});
		},
		actionSetSelectedChatId({ commit }, id) {
			commit('mutationSelectedChatId', id);
		},
		actionChangeCurrentMessage({ commit }, data) {
			commit('mutationSetCurrentMessage', data);
		},
		actionSetAttachments({ commit }, data) {
			commit('mutationSetAttachments', data);
		},
		actionDeleteAttachment({ commit }, fileId) {
			commit('mutationDeleteAttachment', fileId);
		},
		actionAddAttachments({ commit }, data) {
			commit('mutationAddAttachments', data);
		},
	},
	mutations: {
		mutationCurrentChats(state, data) {
			state.chats = data;
		},
		mutationCurrentChatMessages(state, data) {
			state.currentChatMessages = data;
		},
		mutationSearchValue(state, searchValue) {
			state.searchValue = searchValue;
		},
		mutationSelectedChatId(state, id) {
			state.selectedChatId = id;
		},
		mutationOperatorChatId(state, id) {
			state.operatorChatId = id;
		},
		mutationSetCurrentMessage(state, data) {
			state.currentMessage = data;
		},
		mutationMessageOwner(state, data) {
			state.messageOwner = data;
		},
		mutationSetAttachments(state, data) {
			state.attachments = data;
		},
		mutationAddAttachments(state, files) {
			[...files].forEach((file) => {
				state.attachments.push({
					file,
					fileId: randomKey(),
					link: URL.createObjectURL(file),
				});
			});
		},
		mutationDeleteAttachment(state, fileId) {
			state.attachments = state.attachments.filter(
				(file) => file.fileId !== fileId,
			);
		},
		mutationChatLoading(state, bool) {
			state.loading = bool;
		},
		mutationChatListLoading(state, bool) {
			state.chatListLoading = bool;
		},
		mutationInitialChat(state, data) {
			state.initialChat = data;
		},
		mutationCurrentChatUnreads(state, num) {
			const newChats = state.chats.map((chat) => {
				if (chat.chatId === state.selectedChatId) {
					chat.unReadCount = num;
				}
				return chat;
			});
			state.chats = newChats;
		},
	},
	getters: {
		getterCurrentChats(state) {
			const sortShapesByLastMessageCreated = (a, b) => {
				if (!a.lastMessage) {
					return 1;
				}
				if (!b.lastMessage) {
					return -1;
				}

				const aDate = new Date(a.lastMessage?.created ?? Date.now());
				const bDate = new Date(b.lastMessage?.created ?? Date.now());
				return bDate.getTime() - aDate.getTime();
			};

			return state.chats.slice().sort(sortShapesByLastMessageCreated);
		},
		getterChatsSidebarOptions(state) {
			const chatsOptions = state.chats.map((chat) => ({
				name: chat.chatId,
				...chat,
			}));

			const sortShapesByLastMessageCreated = (a, b) => {
				if (!a.lastMessage) {
					return 1;
				}
				if (!b.lastMessage) {
					return -1;
				}

				const aDate = new Date(a.lastMessage?.timestamp ?? Date.now());
				const bDate = new Date(b.lastMessage?.timestamp ?? Date.now());
				return bDate.getTime() - aDate.getTime();
			};

			return chatsOptions.slice().sort(sortShapesByLastMessageCreated);
		},
		getterCurrentChatMessages(state) {
			return state.currentChatMessages;
		},
		getterCurrentMessage(state) {
			return state.currentMessage;
		},
		getterAttachments(state) {
			return state.attachments;
		},
		getterInitialChat(state) {
			return state.initialChat;
		},
		getterHasInitialChat(state) {
			return state.initialChat !== null;
		},
		getterSelectedChatId(state) {
			return state.selectedChatId;
		},
		getterOperatorChatId(state) {
			return state.operatorChatId;
		},
		getterChatsLoading(state) {
			return state.loading;
		},
		getterChatsListLoading(state) {
			return state.chatListLoading;
		},
		getterMessageOwner(state) {
			return state.messageOwner;
		},
		getterSearchValue(state) {
			return state.searchValue;
		},
		getterHasUnreadChats(state) {
			return state.chats.some((chat) => chat.unReadCount >= 1);
		},
	},
};
