import {getApiRequestBuilder, postApiRequestBuilder, putApiRequestBuilder, deleteApiRequestBuilder} from "@utils/RequestBuilder";

export const COMMONS = 'COMMONS';
export const DRAFT_PROJECTS = 'DRAFT_PROJECTS';
export const POPULAR_LPI = 'POPULAR_LPI';
export const POPULAR_INVESTOR = 'POPULAR_INVESTOR';
export const RECOMMENDATIONS_LPI = 'RECOMMENDATIONS_LPI';
export const RECOMMENDATIONS_INVESTOR = 'RECOMMENDATIONS_INVESTOR';

export const COMMONS_LABEL = 'Общие вопросы по инвестиционной платформе';
export const DRAFT_PROJECTS_LABEL = 'Драфт-проекты';
export const POPULAR_INVESTOR_LABEL = 'Популярные вопросы инвестора';
export const POPULAR_LPI_LABEL = 'Популярные вопросы ЛПИ';
export const RECOMMENDATIONS_INVESTOR_LABEL = 'Советы по инвестированию';
export const RECOMMENDATIONS_LPI_LABEL = 'Советы по привлечению инвестиций';

export function getPlatformIssues() {
	return getApiRequestBuilder(`platform-issues`).public.send();
}

export function createPlatformIssues(data) {
	return postApiRequestBuilder(`platform-issues`).send(data);
}

export function updatePlatformIssues(data) {
	return putApiRequestBuilder(`platform-issues/${data.id}`).send(data);
}

export function deletePlatformIssues(id) {
	return deleteApiRequestBuilder(`platform-issues/${id}`).send();
}
