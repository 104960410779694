import {getSettings} from "@services/settings";
import cookieJs from 'cookie_js';

const UNBLOCKED_COOKIE = 'unblocked_cookie';

export default {
	namespaced: true,

	actions: {
		async initPlatformBlockedConfig({ state }) {
			return getSettings([
				'blockPlatformEnabled',
				'blockPlatformTitle',
			]).then(r => {

				const blockPlatformEnabled = r.blockPlatformEnabled === 'true';

				if (blockPlatformEnabled) {
					const keysPressed = new Set();

					document.addEventListener('keydown', (event) => {
						keysPressed.add(event.code);
						if (
							keysPressed.has('KeyB') &&
                            keysPressed.has('KeyM') &&
                            event.ctrlKey &&
                            event.shiftKey
						) {
							event.preventDefault();

							if (state.blockPlatformEnabled) {
								state.blockPlatformEnabled = false;

								cookieJs.set(UNBLOCKED_COOKIE, Date.now(), { expires: 1 });

							} else {
								state.blockPlatformEnabled = true;

								cookieJs.remove(UNBLOCKED_COOKIE);
							}
						}
					});

					document.addEventListener('keyup', (event) => {
						keysPressed.delete(event.code);
					});
				}

				if (blockPlatformEnabled && cookieJs.get(UNBLOCKED_COOKIE, false)) {
					state.blockPlatformEnabled = false;
				} else {
					state.blockPlatformEnabled = blockPlatformEnabled;
				}

				state.blockPlatformTitle = r.blockPlatformTitle;
			});
		},
	},

	state: {
		blockPlatformEnabled: false,
		blockPlatformTitle: '',
	},

	getters: {
		blockPlatformEnabledGetter(state) {
			return state.blockPlatformEnabled;
		},
		blockPlatformTitleGetter(state) {
			return state.blockPlatformTitle;
		},
	}
}