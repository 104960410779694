import {INVEST_TYPE_NAMES} from "@configs/investProject";
import {getSettings} from "@services/settings";

const settingNames = [];
const settingPrefix = 'default_banner_';

for (const type in INVEST_TYPE_NAMES) {
	if (INVEST_TYPE_NAMES[type] === INVEST_TYPE_NAMES.UNKNOWN) {
		continue;
	}

	settingNames.push(settingPrefix + type);
}

export default {
	namespaced: true,

	state: {
		banners: null,
	},
	actions: {
		async fetchBanners({state}) {
			if (!state.banners) {
				state.banners = {};

				const banners = {};

				const dataJson = await getSettings(settingNames);
				const data = {};
				for (const dataJsonKey in dataJson) {
					data[dataJsonKey] = JSON.parse(dataJson[dataJsonKey]);
				}

				for (const type in INVEST_TYPE_NAMES) {
					if (INVEST_TYPE_NAMES[type] === INVEST_TYPE_NAMES.UNKNOWN) {
						continue;
					}

					banners[type] = data[settingPrefix + type] || null;
				}

				state.banners = {
					...banners
				}
			}
		},
	},
	getters: {
		projectBannerGetter(state) {
			return (project) => {
				const type = project?.supposedType || project.type;
				if (typeof project?.banner === 'string') {
					return project?.banner;
				}
				return project?.banner?.link || state.banners[type]?.link || '';
			};
		},
		projectBannerSmallGetter(state) {
			return (project) => {
				const type = project?.supposedType || project.type;
				if (typeof project?.bannerSmall === 'string') {
					return project?.bannerSmall;
				}
				return project?.bannerSmall?.link || state.banners[type]?.link || '';
			};
		},
		bannersGetter(state) {
			return state.banners;
		},
	},
};