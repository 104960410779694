const namesToItems = (obj) => Object.entries(obj).map(e => ({ value: e[0], label: e[1] }));

export const DOCUMENT_ACCESS = {
	PUBLIC: 'PUBLIC',
	PRIVATE: 'PRIVATE',
}

export const DOCUMENT_TYPE_CODE = {
	OTHER_DOCS: 'OTHER_DOCS',
	PROJECT_DOCS: 'PROJECT_DOCS',
	ADD_ISSUE_DECISION: 'ADD_ISSUE_DECISION',
	SECURITIES: 'SECURITIES',
	BUSINESS_PLAN: 'BUSINESS_PLAN',
	SHAREHOLDERS: 'SHAREHOLDERS',
	INVESTMENT_CONTRACT: 'INVESTMENT_CONTRACT',
	INVEST_PROMOTION: 'INVEST_PROMOTION',
	INDIVIDUAL_PROMOTION: 'INDIVIDUAL_PROMOTION',
	INVEST_ATTRACTING: 'INVEST_ATTRACTING',
	BANK_OPERATIONS: 'BANK_OPERATIONS',
	PRESENTATION: 'PRESENTATION',
	FINANCIAL_REPORT: 'FINANCIAL_REPORT',
	AUDITORS_REPORT: 'AUDITORS_REPORT',
	FINANCIAL_ANALYSIS: 'FINANCIAL_ANALYSIS',
	QUAL_INVESTOR: 'QUAL_INVESTOR',
	ENTITY_CHANGE_OWNER_REQUEST: 'ENTITY_CHANGE_OWNER_REQUEST',
	CONTRACT_INVESTMENT_LOAN: 'CONTRACT_INVESTMENT_LOAN',
	CONTRACT_INVESTMENT_CONVERTIBLE_LOAN: 'CONTRACT_INVESTMENT_CONVERTIBLE_LOAN',
	CONTRACT_INVESTMENT_STOCK: 'CONTRACT_INVESTMENT_STOCK',
	CONTRACT_INVESTMENT_UCP: 'CONTRACT_INVESTMENT_UCP',
	PASSPORT: 'PASSPORT',
	REGISTRATION: 'REGISTRATION',
	SELFIE: 'SELFIE',
	EGRUL: 'EGRUL',
	CHARTER: 'CHARTER',
	DECISION: 'DECISION',
	MANAGEMENT: 'MANAGEMENT',
	BENEFICIARIES: 'BENEFICIARIES',
	INDIVIDUAL_ENTREPRENEUR_PROMOTION: 'INDIVIDUAL_ENTREPRENEUR_PROMOTION',
	INDIVIDUAL_ENTREPRENEUR_ATTRACTING: 'INDIVIDUAL_ENTREPRENEUR_ATTRACTING',
	UPDATE_INDIVIDUAL_INVESTOR_PROMOTION: 'UPDATE_INDIVIDUAL_INVESTOR_PROMOTION',
	UPDATE_LEGAL_ENTITY_FOUNDER_PROMOTION: 'UPDATE_LEGAL_ENTITY_FOUNDER_PROMOTION',
	UPDATE_INDIVIDUAL_ENTREPRENEUR_FOUNDER_PROMOTION: 'UPDATE_INDIVIDUAL_ENTREPRENEUR_FOUNDER_PROMOTION',
	UPDATE_LEGAL_ENTITY_INVESTOR_PROMOTION: 'UPDATE_LEGAL_ENTITY_INVESTOR_PROMOTION',
	UPDATE_INDIVIDUAL_ENTREPRENEUR_INVESTOR_PROMOTION: 'UPDATE_INDIVIDUAL_ENTREPRENEUR_INVESTOR_PROMOTION',
}

export const DOCUMENT_TYPE_SCOPES = {
	PARTICIPANT: 'PARTICIPANT',
	ENTITY: 'ENTITY',
	PERSON: 'PERSON',
	PROJECT: 'PROJECT',
	CONTRACT_INVESTMENT: 'CONTRACT_INVESTMENT',
}

export const DOCUMENT_TYPE_SCOPE_NAMES = {
	ENTITY: 'Анкета',
	PARTICIPANT: 'Участник',
	PERSON: 'Физическое лицо',
	PROJECT: 'Проект',
	CONTRACT_INVESTMENT: 'Договоры инвестирования',
}
export const DOCUMENT_TYPE_SCOPE_ITEMS = namesToItems(DOCUMENT_TYPE_SCOPE_NAMES);

export const DOCUMENT_TEMPLATE_SCOPE_NAMES = {
	PARTICIPANT: 'Участник',
	PROJECT: 'Проект',
	INVESTMENT: 'Инвестиция в проект',
	COLLATERAL: 'Обеспечение по проекту',
	OPERATION: 'Операция',
	CONTRACT_INVESTMENT: 'Договоры инвестирования',
	ENTITY_PROMOTION: 'Изменение анкетных данных',
	SHARES_TRANSFER_ORDER: 'Передаточное распоряжение (акции)',
	PAYMENT_ORDER: 'Платежные поручения',
	ENTITY_CHANGE_OWNER_REQUEST: 'Заявление на смену пользователя',
}
export const DOCUMENT_TEMPLATE_SCOPE_ITEMS = namesToItems(DOCUMENT_TEMPLATE_SCOPE_NAMES);

export const ACCESS_CODE_NAMES = {
	PRIVATE: 'Ограниченный',
	PUBLIC: 'Общий',
}
export const ACCESS_CODE_ITEMS = namesToItems(ACCESS_CODE_NAMES);

export const SIGNER_TYPES = {
	USER_ENTITY: 'USER_ENTITY',
	PARTICIPANT: 'PARTICIPANT',
}

export const SIGNATURE_TYPES = {
	QUALIFIED: 'QUALIFIED',
	SIMPLIFIED: 'SIMPLIFIED',
}

export const SIGNATURE_TYPES_NAMES = {
	QUALIFIED: 'УКЭП',
	SIMPLIFIED: 'ПЭП',
}

export const CONTRACT_SUBJECTS = {
	ASSISTANCE_INVESTMENT: 'ASSISTANCE_INVESTMENT',
	ATTRACTION_INVESTMENT: 'ATTRACTION_INVESTMENT',
}

export const CONTRACT_SUBJECT_NAMES = {
	ASSISTANCE_INVESTMENT: 'Оказание услуг по содействию в инвестировании',
	ATTRACTION_INVESTMENT: 'Оказание услуг по привлечению инвестиций',
}
