import omit from 'lodash.omit';
import {
	createDraftPitch,
	createPitch,
	deletePitch,
	changeStatus,
	editDraftPitch,
	getPitch,
	moderation as pitchModeration, getPitchPublic
} from "@services/projects";

const SERVICE_TYPE = 'pitches';

const STATUS_PUBLISHED = 'PUBLISHED';

export default {
	namespaced: true,

	state: {
		pitch: {},
		pitchLoader: true,
		pitchLoaderBlock: false,
		pitchCreationStage: "init", // 'init' | 'invalid' | 'valid',
		filesUploading: true,
		isEdited: false,
		readonly: true,
		pitchForm: null,

		mode: 'OWNER',
	},

	actions: {
		async actionGetPitch({ commit }, id) {
			commit('mutationPitchLoader', true);
			return getPitch(id).then(r => {
				commit('setPitch', r.data);
			})
		},
		async actionGetPitchPublic({ commit }, id) {
			commit('mutationPitchLoader', true);
			return getPitchPublic(id).then(r => {
				commit('setPitch', r.data);
			})
		},
		actionSetPitch({ commit }, data) {
			commit('mutationIsEdited', true);
			commit('setPitch', data);
		},
		actionSetPitchLoader({ commit }, bool) {
			commit('mutationPitchLoader', bool);
		},
		actionClearStatePitch({ commit }) {
			commit('mutationIsEdited', false);
			commit('setPitch', {});
		},
		actionSetFilesUploading({ commit }, bool) {
			commit('mutationFilesUploading', bool);
		},
		actionSetPitchForm({ commit }, form) {
			commit('mutationPitchForm', form);
		},
		actionSetEdited({ commit }, bool) {
			commit('mutationIsEdited', bool);
		},
		async actionValidatePitchForm({ state }) {
			return state.pitchForm.validate();
		},
		async actionCreateDraft({ state, commit }) {
			commit('mutationPitchLoader', true);
			return createDraftPitch(state.pitch).then(r => {
				commit('setPitch', r.data);
			})
		},
		async actionUpdateDraft({ commit, state, dispatch }) {
			commit('mutationPitchLoader', true);

			return editDraftPitch(state.pitch).then(() => {
				commit('mutationIsEdited', false);
				return dispatch('pitch/actionGetPitch', state.pitch.id, { root: true });
			})
		},
		async actionCreatePitch({ commit, state }) {
			commit('mutationPitchLoader', true);

			return createPitch(state.pitch).then(r => {
				commit('setPitch', r.data);
			})
		},
		actionDeletePitch({state}) {
			return deletePitch(state.pitch.id);
		},
		actionSetApproveStatus({ state }) {
			return pitchModeration.approvePitch(state.pitch.id);
		},
		actionSetRejectStatus({ state }, reason) {
			return pitchModeration.reject(SERVICE_TYPE, state.pitch.id, reason);
		},
		actionSetReadonly({ commit }, bool) {
			commit('mutationReadonly', bool);
		},
		async actionChangePitchStatus({commit, state}, status) {
			return changeStatus(state.pitch.id, status).then(() => {
				commit('setPitch', {...state.pitch, status});
				return state.pitch;
			});
		},
		actionSetPitchType({ commit, state, rootGetters }, type) {
			const types = rootGetters['dictionary/projectTypes']?.map(t => t.type);

			if (types && types.includes(type)) {
				commit('setPitch', { ...state.pitch, supposedType: type });
				return;
			}
			console.error('Попытка установить неизвестный тип: ' + type);
		},
		actionSetModeType({ commit }, mode) {
			commit('mutationModeType', mode);
		},
		actionApplyProjectBasedOnPitch({ dispatch, getters }) {
			if (!getters.getterPitchSafeForProject) {
				throw 'actionApplyProjectBasedOnPitch: pitch must not be null';
			}

			dispatch(
				'project/actionPutToStateProjectFromPitch',
				getters.getterPitchSafeForProject,
				{ root: true },
			);
		},
	},

	mutations: {
		changePitchCreationState(state, stage) {
			state.pitchCreationStage = stage;
		},
		mutationReadonly(state, bool) {
			state.readonly = bool;
		},
		mutationFilesUploading(state, bool) {
			state.filesUploading = bool;
		},
		mutationIsEdited(state, bool) {
			state.isEdited = bool;
		},
		mutationPitchLoader(state, bool) {
			state.pitchLoader = bool;
		},
		mutationPitchForm(state, form) {
			state.pitchForm = form;
		},
		mutationModeType(state, mode) {
			state.mode = mode;
		},
		setPitch(state, data) {
			state.pitch = {...data};

			if (!state.pitchLoaderBlock) {
				setTimeout(() => state.pitchLoader = false, 500);
			}
		},
	},

	getters: {
		getPitch(state) {
			return state.pitch;
		},
		getPitchCreationStage(state) {
			return state.pitchCreationStage;
		},
		getterPitchLoader(state) {
			return state.pitchLoader;
		},
		getterPitchIsNew(state) {
			return state.pitch.id === null || state.pitch.id === undefined;
		},
		getterHasPitch(state) {
			return Object.keys(state.pitch).length > 0;
		},
		getterPitchInStatusPublished(state) {
			return state.pitch?.status === STATUS_PUBLISHED;
		},
		getterIsPitchOwner(state) {
			return state.pitch?.isOwner;
		},
		getterIsReadonly(state) {
			return state.readonly;
		},
		getterPitchMode(state) {
			return state.mode;
		},
		getterFilesUploading(state) {
			return state.filesUploading;
		},
		getterIsEdited(state) {
			return state.isEdited;
		},
		getterIsPitchOwnerAndPublished(state) {
			return state.pitch?.isOwner && state.pitch?.status === STATUS_PUBLISHED;
		},
		getterPitchSafeForProject(state) {
			if (!state.pitch) return null;

			return {
				parentId: state.pitch.id,
				type: state.pitch.supposedType,
				...omit(state.pitch, [
					'id',
					'followed',
					'followers',
					'interested',
					'investmentForecastFrom',
					'investmentForecastTo',
					'isOwner',
					'publishDate',
					'publishTerm',
					'rejectReason',
					'status',
					'supposedType',
					'type',
					'watched',
					'target',
				]),
			};
		},
	},
};
