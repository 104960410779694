import Requests from '@utils/Requests.js';
import api from '@configs/api.js';
import pool from './pool';

const POOL_MIN_SIZE = 6291456;

/**
 * Загрузка файла
 * @param {File} file Загружаемый файл
 * @returns {Promise<{ file, fileId, fileUrl }>} Промис с объектом { file, fileId, fileUrl }
 */
function uploadFile(file) {
	if (file) {
		if (file.size >= POOL_MIN_SIZE) {
			return new Promise((resolve, reject) => {
				Requests.postRequest(
					`/${api.url}files/init`,
					{
						name: file.name,
						type: file.type,
						size: file.size,
					},
					{},
					true
				)
					.then(res => {
						if (res.status === 201) {
							pool().enqueue(file, res.data.fileId)
								.then(() => {
									Requests.postRequest(
										`/${api.url}files/complete/${res.data.fileId}`,
										"",
										{},
										true
									)
										.then(resFileComplete => {
											if (resFileComplete.status === 200 || resFileComplete.status === 201) {
												const completedFile = resFileComplete.data;
												resolve({
													file: completedFile.file,
													fileId: completedFile.fileId,
													link: completedFile.link,
												});
											} else {
												reject({
													status: resFileComplete.status,
													message: resFileComplete.message,
													file,
												});
											}
										})
										.catch(err => {
											reject(err);
										})
								})
								.catch(err => {
									reject(err);
								})
						} else {
							reject({
								status: res.status,
								message: res.message,
								file,
							});
						}
					})
					.catch(err => {
						reject(err);
					})
			})
		} else {
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('file', file);
				try {
					Requests.requestWrapper(
						Requests.postRequest(
							`/${api.url}files`,
							formData,
							{
								timeout: 0,
								processData: false,
								mimeType: 'multipart/form-data',
								contentType: false,
							},
							true
						)
					)
						.then((res) => {
							if (res.status === 200 || res.status === 201) {
								resolve({
									file: res.data.file,
									fileId: res.data.fileId,
									link: res.data.link,
								});
							} else {
								reject({
									status: res.status,
									message: res.message,
									file,
								});
							}
						})
						.catch((err) => {
							reject(err);
						});
				} catch (error) {
					reject(error);
				}
			});
		}
	}
	return null;	
}

/**
 * Удаление файла
 * @param {FileSpec} file Удаляемый файл
 * @returns {Promise<{ file }>} Промис с объектом { file, fileId, fileUrl }
 */
function deleteFile(file) {
	//TODO Вызывать endpoint удаления файла (когда появится)
	return Promise.resolve(file);
}

async function getFileInfo(fileId) {
	const END_POINT = `files/${fileId}/info`;

	try {
		const res = await Requests.requestWrapper(
			Requests.getRequest(`/${api.url}${END_POINT}`, {}, true)
		);

		return res.data;
	} catch (error) {
		throw new Error(error);
	}
}

async function getFile(fileId) {
	const END_POINT = `files/${fileId}`;

	try {
		const res = await Requests.requestWrapper(
			Requests.getRequest(
				`/${api.url}${END_POINT}`,
				{ responseType: 'arraybuffer' },
				true
			)
		);

		return res.data;
	} catch (error) {
		throw new Error(error);
	}
}

async function getFilePublic(fileId) {
	const END_POINT = `files/${fileId}`;

	try {
		const res = await Requests.requestWrapper(
			Requests.getRequest(
				`/${api.url}${END_POINT}`,
				{ responseType: 'arraybuffer' },
			)
		);

		return res.data;
	} catch (error) {
		throw new Error(error);
	}
}

async function getFileBySignRequest(fileId, requestId) {
	const END_POINT = `/sign/request/${requestId}/files/${fileId}`;

	try {
		const res = await Requests.requestWrapper(
			Requests.getRequest(
				`/${api.url}${END_POINT}`,
				{ responseType: 'arraybuffer' },
			)
		);

		return res.data;
	} catch (error) {
		throw new Error(error);
	}
}

export {
	getFileInfo,
	getFile,
	uploadFile,
	deleteFile,
	getFilePublic,
	getFileBySignRequest,
};
