import {
	createDraftProject,
	createProject,
	editDraftProject,
	editProject,
	getProject,
	getPublicProject,
	getRenderedTemplateOffer,
	updateShareholders,
	moderation as projectModeration
} from "@services/projects";
import { getProjectLoanDebt, repaymentLoan, RATE_TYPES_CODES, FLOATING_RATE_SCHEMES } from '@/services/projects/project';
import {INVEST_TYPE_OFFER_DOCS, INVEST_TYPES, INVEST_STATUSES, COLLATERAL_TYPES_SIGNED_BY_THIRD_PARTY} from "@configs/investProject";
import Vue from "vue";
import {fromArrayToObject, fromObjectToArray} from "@utils/common";
import {DOCUMENT_TYPE_CODE} from "@configs/documents";
import {isDocumentSigned, isDocumentSignedByThirdParty} from "@utils/documents";
import { SINGER_TYPES } from '@/configs/participants';
import omit from 'lodash.omit';

const SERVICE_TYPE = 'projects';

const mapProjectFields = (r) => {
	if (Array.isArray(r.data.collateralTypes)) {
		r.data.collateralTypes = r.data.collateralTypes.map(
			(collateral) => ({ ...collateral, __saved: true, agreement1: true, agreement2: true }),
		);
	}

	r.data.collateralExists = r.data.collateralTypes && r.data.collateralTypes.length > 0;

	if (!r.data.govContract) {
		r.data.govContract = {};
	}

	return r;
}

export default {
	namespaced: true,

	actions: {
		async actionGetProject({ commit }, id) {
			commit('mutationSetLoader', true);
			return getProject(SERVICE_TYPE, id).then(r => {

				r = mapProjectFields(r);

				commit('mutationSetUneditedProjectCopy', r.data);
				commit('mutationSetProject', r.data);
			})
		},
		async actionGetPublicProject({ commit }, id) {
			commit('mutationSetLoader', true);
			return getPublicProject(SERVICE_TYPE, id).then(r => {

				r = mapProjectFields(r);

				commit('mutationSetProject', r.data);
			})
		},
		async actionCreateDraft({ state, commit }) {
			commit('mutationSetLoader', true);
			return createDraftProject(state.project).then(r => {
				r = mapProjectFields(r);
				commit('mutationSetProject', r.data);
			})
		},
		async actionUpdateDraft({ state, dispatch, commit }) {
			commit('mutationSetLoader', true);
			return editDraftProject(state.project).then(() => {
				return dispatch('project/actionGetProject', state.project.id, { root: true });
			})
		},
		async actionCreateProject({ commit, state }) {
			commit('mutationSetLoader', true);
			return createProject(state.project).then(r => {
				r = mapProjectFields(r);
				commit('mutationSetProject', r.data);
			})
		},
		async actionUpdateProject({ state, dispatch, commit }) {
			commit('mutationSetLoader', true);

			const temp = INVEST_TYPE_OFFER_DOCS[state.project.type];

			state.project.documents = state.project.documents.filter((doc) => doc.typeCode !== temp);
			commit('mutationSetSignDoc', null);

			return editProject(SERVICE_TYPE, state.project).then(() => {
				return dispatch('project/actionGetProject', state.project.id, { root: true });
			})
		},

		async actionUpdateShareholders({ state }, shareholdersRequest) {
			return updateShareholders(state.project.id, shareholdersRequest.shareholders, shareholdersRequest.stopRender);
		},

		async actionCreateDocForSing({ state, commit }) {
			const projectId = state.project.id;

			commit('mutationSetSignDoc', null);

			return getRenderedTemplateOffer(projectId).then((r) => {
				commit('mutationSetSignDoc', r.data);
			});
		},
		async actionValidateForm({ state }) {
			return state.formParticipant.validate() && state.formType.validate() && state.formGeneral.validate();
		},

		async actionSetApproveStatus({ state }, { rating, operatorFee }) {
			return projectModeration.approve(SERVICE_TYPE, state.project.id, { rating, operatorFee });
		},
		async actionSetRejectStatus({ state }, rating) {
			return projectModeration.reject(SERVICE_TYPE, state.project.id, rating);
		},

		actionPutToStateProject({ commit }, data) {
			commit('mutationSetEdited', true);
			if (data.propertyInformationRights === undefined) {
				data.propertyInformationRights = false;
			}
			if (data.investorHasPreemptiveRights === undefined && data.type === INVEST_TYPES.STOCK) {
				data.investorHasPreemptiveRights = false;
			}
			if (data.documentsObj) {
				data.documents = fromObjectToArray(data.documentsObj).filter(file => file !== null);
			}

			commit('mutationSetProject', data);
		},
		actionPutToStateProjectFromPitch({ commit, state }, data) {
			data.participantId = state.project.participantId;
			commit('mutationSetProject', data);
		},
		actionAddMemberProject({ state, commit }, member) {
			commit('mutationSetEdited', true);
			if (!state.project?.team?.members) {
				if (!state.project.team) {
					Vue.set(state.project, 'team', {});
				}

				Vue.set(state.project.team, 'members', [member]);

				return;
			}
			state.project.team.members.push(member);

		},
		actionEditMemberProject({ state, commit }, {member, index}) {
			Vue.set(state.project.team.members, index, member);
			commit('mutationSetEdited', true);
		},
		actionDeleteMemberProject({ state, commit }, index) {
			Vue.delete(state.project.team.members, index)
			commit('mutationSetEdited', true);
		},
		actionAddUcpProject({ commit }, ucp) {
			commit('mutationSetEdited', true);
			commit('mutationAddUcpProject', ucp);
		},
		actionEditUcpProject({ commit }, {ucp, index}) {
			commit('mutationSetEdited', true);
			commit('mutationEditUcpProject', { ucp, index });
		},
		actionDeleteUcpProject({ commit }, index) {
			commit('mutationSetEdited', true);
			commit('mutationDeleteUcpProject', index);
		},
		actionSetParticipantId({ commit, state }, participantId) {
			Vue.set(state.project, 'participantId', participantId);
			commit('mutationSetProject', state.project);
		},
		async actionSetProjectType({ commit, state, rootGetters }, type) {
			await rootGetters['dictionary/projectTypesLoadingState'].promise;

			const types = rootGetters['dictionary/projectTypes']?.map(t => t.type);

			if (types && types.includes(type)) {
				Vue.set(state.project, 'type', type);
				commit('mutationSetProject', state.project);

				return;
			}
			console.error('Попытка установить неизвестный тип: ' + type);
		},
		actionSetOperatorFee({ commit }, operatorFee) {
			commit('mutationSetOperatorFee', operatorFee);
		},
		actionSetCollateralTypes({ commit }, collateralTypes) {
			commit('mutationSetEdited', true);
			commit('mutationSetCollateralTypes', collateralTypes);
		},
		actionSetCollateralExists({ commit }, value) {
			commit('mutationSetCollateralExists', value);
		},
		actionSetInterestRate({ commit }, interestRate) {
			commit('mutationSetInterestRate', interestRate);
		},
		actionSetDebtData({ commit, state }) {
			getProjectLoanDebt(state.project.id).then((res) => {
				commit('mutationDebtData', res.data);
			})
		},
		actionPostRepaymentLoan({ state }, { earlyTotalDebt, earlyRepayment }) {
			return repaymentLoan(state.project.id, earlyTotalDebt, earlyRepayment);
		},
		actionSetFilesUploading({ commit }, bool) {
			commit('mutationFilesUploading', bool);
		},
		actionSetCollateralFilesUploading({ commit }, bool) {
			commit('mutationCollateralFilesUploading', bool);
		},
	},

	mutations: {
		mutationSetInterestRate(state, interestRate) {
			Vue.set(state.project, 'interestRate', interestRate);
		},
		mutationSetCollateralTypes(state, collateralTypes) {
			Vue.set(state.project, 'collateralTypes', collateralTypes);
		},
		mutationSetCollateralExists(state, value) {
			Vue.set(state.project, 'collateralExists', value);
		},
		mutationSetOperatorFee(state, collateralTypes) {
			Vue.set(state.project, 'operatorFee', collateralTypes);
		},
		mutationSetUneditedProjectCopy(state, data) {
			state.uneditedProjectCopy = structuredClone(data);
		},
		mutationSetProject(state, data) {

			if (data.rateType === RATE_TYPES_CODES.FLOATING && !FLOATING_RATE_SCHEMES.includes(data.paymentSchemeCode)) {
				data.rateType = null;
			}

			state.project = data;

			if (state.project.documents) {
				data.documentsObj = {
					...fromArrayToObject(data.documents, 'typeCode'),
					PROJECT_DOCS: data.documents.filter(d => d.typeCode === DOCUMENT_TYPE_CODE.PROJECT_DOCS),
					BANK_OPERATIONS: data.documents.filter(d => d.typeCode === DOCUMENT_TYPE_CODE.BANK_OPERATIONS),
					PRESENTATION: data.documents.filter(d => d.typeCode === DOCUMENT_TYPE_CODE.PRESENTATION),
				}

				const temp = INVEST_TYPE_OFFER_DOCS[state.project.type];
				state.signDoc = data.documentsObj[temp] || null;
			}

			if (!state.loaderBlock) {
				setTimeout(() => state.loader = false, 500);
			}
		},
		mutationSetEdited(state, bool) {
			state.isEdited = bool;
		},
		mutationSetLoader(state, bool) {
			state.loader = bool;
		},
		mutationSetLoaderBlock(state, bool) {
			state.loaderBlock = bool;
		},
		mutationSetSignDoc(state, signDoc) {
			state.signDoc = signDoc;
		},
		mutationSetReadonly(state , bool) {
			state.readonly = bool;
		},
		mutationSetFormGeneral(state, form) {
			state.formGeneral = form;
		},
		mutationSetFormType(state , form) {
			state.formType = form;
		},
		mutationSetModeType(state, mode) {
			state.mode = mode;
		},
		mutationDebtData(state, data) {
			state.debtData = data;
		},
		mutationSetFormParticipant(state, form) {
			state.formParticipant = form;
		},
		mutationClearStateProject(state) {
			state.project = {
				rateType: 'FIXED'
			};
			state.mode = 'OWNER';
			state.documentForSign = null;
			state.formParticipant = null;
			state.formType = null;
			state.formGeneral = null;
			state.signDoc = null;
		},
		setProject(state, data) {
			state.project = data;
		},
		mutationAddUcpProject(state, ucp) {
			if (!state.project.ucp) {
				Vue.set(state.project, 'ucp', [ucp]);
				return;
			}

			state.project.ucp.push(ucp);
		},
		mutationEditUcpProject(state, { index, ucp }) {
			Vue.set(state.project.ucp, index, ucp);
		},
		mutationDeleteUcpProject(state, index) {
			Vue.delete(state.project.ucp, index)
		},
		mutationFilesUploading(state, bool) {
			state.filesUploading = bool;
		},
		mutationCollateralFilesUploading(state, bool) {
			state.collateralFilesUploading = bool;
		},
	},

	state: {
		project: {},
		uneditedProjectCopy: null,
		loader: true,
		loaderBlock: false,
		readonly: true,
		filesUploading: true,
		collateralFilesUploading: true,
		isEdited: false,
		mode: 'OWNER',
		documentForSign: null,
		formParticipant: null,
		formType: null,
		formGeneral: null,
		signDoc: null,

		debtData: {},


		projectCreationStage: "init", // 'init' | 'invalid' | 'valid'
	},

	getters: {
		getterProject(state) {
			return state.project;
		},
		getterUneditedProjectCopy(state) {
			return state.uneditedProjectCopy;
		},
		getterProjectClean(state) {
			return omit(state.project, ['documentsObj']);
		},
		getterProjectId(state) {
			return state.project.id;
		},
		getterProjectIsNew(state) {
			return state.project.id === null || state.project.id === undefined;
		},
		getterParticipantId(state) {
			return state.project.participantId;
		},
		getterProjectMode(state) {
			return state.mode;
		},
		getterHasProject(state) {
			return Object.keys(state.project).length > 0;
		},
		getterIsLoader(state) {
			return state.loader;
		},
		getterIsReadonly(state) {
			return state.readonly;
		},
		getterIsShareholdersCanUpdate(state) {
			return state.canShareholdersUpdate;
		},
		getterStatus(state) {
			return state.project.status;
		},
		getterIsOwner(state) {
			return state.project.isOwner;
		},
		getterDebtData(state) {
			return state.debtData;
		},
		getterIsInvestor(state) {
			return state.project.investor;
		},
		getterHasSignDoc(state) {
			return state.signDoc !== null && isDocumentSigned(state.signDoc);
		},
		getterGetSignDoc(state) {
			return state.signDoc;
		},
		getterProjectIsInStatusDraft(state) {
			return state.project.status === INVEST_STATUSES.DRAFT;
		},
		getterIsEdited(state) {
			return state.isEdited;
		},
		getterDocumentsForSingAll(state, getters) {
			if (!getters.getterGetSignDoc) {
				return [];
			}
			if (getters.getterCorporateAgreement?.length && getters.getterCorporateAgreement.length > 0) {
				return [getters.getterGetSignDoc, ...getters.getterCorporateAgreement, ...getters.getterCollateralDocuments];
			}
			if (getters.getterSharesholdersAgreement?.length && getters.getterSharesholdersAgreement.length > 0) {
				return [getters.getterGetSignDoc, ...getters.getterSharesholdersAgreement, ...getters.getterCollateralDocuments];
			}
			return [getters.getterGetSignDoc, ...getters.getterCollateralDocuments];
		},
		getterDocumentsForSingAllFinishSing(state, getters) {
			return getters.getterDocumentsForSingAll.every(d => isDocumentSigned(d));
		},
		getterOperatorFee(state) {
			return state.project.operatorFee;
		},
		getterCollateralExists(state) {
			return state.project.collateralExists;
		},
		getterCollateralTypes(state) {
			return state.project.collateralTypes;
		},
		getterCollateralDocuments(state) {
			return state.project.collateralTypes?.flatMap(c => c.documents) ?? [];
		},
		getterHasThirdPartyDocuments(state, getters) {
			return getters.getterLegalDocuments.some(isDocumentSignedByThirdParty);
		},
		getterCorporateAgreement(state) {
			return state.project.documents?.filter(doc => doc.typeCode === 'CORPORATE_AGREEMENT');
		},
		getterHasCorporateAgreement(state) {
			return state.project.documents?.filter(doc => doc.typeCode === 'CORPORATE_AGREEMENT').length > 0;
		},
		getterSharesholdersAgreement(state) {
			return state.project.documents?.filter(doc => doc.typeCode === 'SHAREHOLDERS_AGREEMENT');
		},
		getterHasSharesholdersAgreement(state) {
			return state.project.documents?.filter(doc => doc.typeCode === 'SHAREHOLDERS_AGREEMENT').length > 0;
		},
		getterHasCollateralTypes(state) {
			return state.project.collateralTypes && state.project.collateralTypes.length;
		},
		getterCollateralTypesSignedByThirdParty(_, getters) {
			if (!Array.isArray(getters.getterCollateralTypes)) {
				return [];
			}
			return getters.getterCollateralTypes.filter(c => COLLATERAL_TYPES_SIGNED_BY_THIRD_PARTY.includes(c.collateralType));
		},
		getProjectCreationStage(state) {
			return state.projectCreationStage;
		},
		getProject(state) {
			return state.project;
		},
		getterLegalDocuments(state) {
			return state.project.documents
				.concat((state.project.collateralTypes ?? []).flatMap((c) => c.documents))
				.filter((doc) => doc.legalDoc)
		},
		getterDocumentsForSign(state) {
			return state.project.documents
				.filter((doc) => doc.legalDoc)
				.concat((state.project.collateralTypes ?? []).flatMap((c) => c.documents))
				.filter(doc => doc.signs.some((sign) => [SINGER_TYPES.PARTICIPANT, SINGER_TYPES.ENTITY].includes(sign.signerType) && sign.signerId === state.project.participantId));
		},
		getterFilesUploading(state) {
			return state.filesUploading;
		},
		getterCollateralFilesUploading(state) {
			return state.collateralFilesUploading;
		},
	},
};
