import {
	getParticipantData,
	getParticipantSettings,
} from '@services/participant';
import { getProjectsMine } from '@services/projects';
import { PROJECT_STATUSES } from '@services/status';
import { fromArrayToObject } from '@/utils/common';
import KC from '@/keycloak';
import Vue from 'vue';
import { mapParticipantSettings } from '../../services/participant';

/**
 * @type {import('vuex').Module
 */
export default {
	namespaced: true,
	state: {
		profile: {
			beneficiary: {},
			gainsBenefit: {},
			qualification: {},
			organization: {},
			documents: {},
		},
		profilePath: undefined,
	},
	actions: {
		async setParticipant({ dispatch, state, rootGetters }, id) {
			if (id == -1) {
				state.profile = {
					beneficiary: {},
					gainsBenefit: {},
					qualification: {},
					organization: {},
					documents: {},
				};
				state.profilePath = undefined;
			}

			if (id && !KC.instance().hasRole('operator')) {
				await getProjectsMine().then((projectsData) => {
					const projects = projectsData.data.items;
					const hasReleasedOrCancelledProjects = projects
						.filter((p) => p.participantId === id)
						.some(
							(p) => !!p.released || p.status === PROJECT_STATUSES.CANCELLED,
						);

					Vue.set(state.profile, 'hasReleasedOrCancelledProjects', hasReleasedOrCancelledProjects);
				});
				await getParticipantData(id).then((participantData) => {
					if (participantData.data) {
						dispatch('setPath', participantData.data);

						state.profile = {
							...participantData.data
						}

						state.profile.beneficiary = state.profile.beneficiary ?? {};
						state.profile.gainsBenefit = state.profile.gainsBenefit ?? {};

						state.profile.qualification = state.profile.qualification ?? {};

						state.profile.organization = state.profile.organization ?? {};

						state.profile.documents = fromArrayToObject(
							state.profile.documents,
							'typeCode',
						);
					}
				});
			}

			if (id) {
				const participantSettings = await getParticipantSettings(id);
				Vue.set(state.profile, 'settings', mapParticipantSettings(participantSettings));
				if (state.profile.settings.DISABLE_INTEREST_RATE_FORMULA && !rootGetters['project/getterProject'].interestRate) {
					dispatch('project/actionSetInterestRate', null, { root: true });
				}
			}
		},

		setPath({ state }, data) {
			if (data.type === 'INDIVIDUAL' && data.role === 'INVESTOR') {
				return (state.profilePath = 'invest-pers');
			}

			if (data.type === 'INDIVIDUAL_ENTREPRENEUR' && data.role === 'INVESTOR') {
				return (state.profilePath = 'invest-ip');
			}

			if (data.type === 'LEGAL_ENTITY' && data.role === 'INVESTOR') {
				return (state.profilePath = 'invest-org');
			}

			if (data.type === 'INDIVIDUAL_ENTREPRENEUR' && data.role === 'FOUNDER') {
				return (state.profilePath = 'lpu-ip');
			}

			if (data.type === 'LEGAL_ENTITY' && data.role === 'FOUNDER') {
				return (state.profilePath = 'lpu-org');
			}

			return (state.profilePath = undefined);
		},
	},

	getters: {
		getPath(state) {
			return state.profilePath;
		},
		fetchTaxDisabled(state) {
			return state.profile.settings?.DISABLE_TAX_FORMULA ?? true;
		},
		fetchInterestRateDisabled(state) {
			return state.profile.settings?.DISABLE_INTEREST_RATE_FORMULA ?? true;
		},
		getProfile(state) {
			return state.profile;
		},
	},
};
