<template>
	<div class="ui-table-list">
		<div v-if="isHeaderShown" class="ui-table-list__header">
			<slot name="header" v-bind="{ tableTitle, ...slotMethodsAndProps }">
				<slot name="table-title" v-bind="{ tableTitle, ...slotMethodsAndProps }">
					<div v-if="tableTitle">
						<div class="flex flex-sb gap-24">
							<h2 :class="tableTitleClasses">{{ tableTitle }}</h2>
							<UiButton
								v-if="showVisibilityToggle"
								:label="isTableVisible ? 'Скрыть' : 'Показать'"
								size="xs"
								type="link"
								@click="toggleVisibility"
							/>
						</div>
					</div>
				</slot>
				<UiFilterList
					:filters="filters"
					:initialFilters="filterData"
					@change="setFilterData"
					@filtersChanged="filtersChanged"
					@refetch="fetchTableData"
				/>
			</slot>
		</div>
		<div
			ref="toggleHeightTarget"
			v-toggle-height
		>
			<slot name="table-prepend" v-bind="{ ...currentTable }"/>
			<slot name="content" v-bind="{ items: currentTable.items ?? [], tableColumns, ...slotMethodsAndProps }">
				<UiTable
					v-if="!hideTable"
					id="ui-table-list__table"
					ref="table"
					:borderless="borderless"
					:columns="tableColumns"
					:emptyDataLabel="emptyDataLabel"
					:items="currentTable.items ?? []"
					:loading="currentTable.loader"
					:rowStyle="getRowStyle"
					:sortBy="sortBy"
					:sortDirection="sortDirection"
					:sticky="sticky"
					:touchable="touchable"
					class="ui-table-list__table"
					tableListFormat
					v-bind="$attrs"
					@open="(item) => $emit('openItem', item)"
					@selectedRowKeyChange="$emit('selectedRowKeyChange', $event)"
					@sortByChange="setSortBy"
					@sortDirectionChange="setSortDirection"
				>
					<template v-for="(slot, name) in $scopedSlots" v-slot:[name]="slotProps">
						<slot :name="name" v-bind="{ ...slotProps, ...slotMethodsAndProps }"/>
					</template>
				</UiTable>
			</slot>
			<slot name="empty" v-bind="{ emptyDataLabel, ...slotMethodsAndProps }"/>
			<slot name="table-append" v-bind="{ ...currentTable }"/>
			<div v-if="!hideTable" class="ui-table-list__pagination">
				<UiPagination
					v-if="isPaginationVisible"
					:currentPage="currentPage"
					:pageSize="pageSize"
					:title="paginationTitle"
					:total="currentTable.totalCount"
					class="ui-table-list__pagination mt-10 mb-6"
					@changePage="(number) => (currentPage = number)"
				>
					<template #total="{ total, title }">
						<span v-if="title && mediaForDesktopVal" class="total" style="margin-left: auto; margin-top: 0;">{{ total }} {{
							title
						}}</span>
						<UiSelectNew
							v-if="mediaForDesktopVal"
							v-model="pageSize"
							:options="pageSizeOptions"
							class="ml-16"
							@change="updatePageIndex"
						/>
					</template>
				</UiPagination>
				<UiButton
					v-if="isWatchMoreVisible"
					:pending="currentTable.lazyLoader"
					class="ui-table-list__watchmore-btn"
					label="Посмотреть еще"
					@click="watchMore"
				/>
			</div>
			<UiExportToExcel
				v-if="canExport && isTableVisible"
				:sheets="sheets"
				class="mt-16"
			/>
			<slot name="actions" v-bind="{ ...slotMethodsAndProps, canExport }"/>
			<slot name="additional" v-bind="{ ...slotMethodsAndProps }"/>
		</div>
	</div>
</template>

<script>
import {UI_TABLE_LIST_PAGE_SIZE_DEFAULT, UI_TABLE_LIST_PAGE_SIZE_OPTIONS} from '@configs/ui';
import {ORDER_BY} from "@services/tables";
import toggleHeight from '@/directives/toggleHeight';
import {convertToSnakeCase} from "@utils/format";

export default {
	name: 'UiTableList',
	components: {
		UiSelectNew: () => import('@/components/UI/UiSelectNew/UiSelectNew.vue'),
	},
	directives: {
		toggleHeight,
	},
	inject: ['mediaForDesktop'],
	props: {
		/** Именованный акшен для вызова табличного списка */
		tableAction: {
			type: String,
			required: true
		},
		/** Именованный геттер для обращения к табличному списку */
		tableGetter: {
			type: String,
			required: true
		},
		/** Колонки таблицы */
		tableColumns: {
			type: Array,
			default: () => []
		},
		/** Название таблицы */
		tableTitle: {
			type: String,
			default: ''
		},
		/** Белый фон строк таблицы */
		whiteRowStyle: {
			type: Boolean,
			default: false,
		},
		/** Фиксация шапки таблицы */
		sticky: {
			type: Boolean,
			default: false,
		},
		/** Состояние параметров в Url */
		enabledUrlQuery: {
			type: Boolean,
			default: true,
		},
		/** Функция вычисления стиля строки (элемента tr) */
		tableRowStyle: {
			type: Function,
			default: () => '',
		},
		/** Название элементов пагинации */
		paginationTitle: {
			type: String,
			default: 'элементов'
		},
		initialSort: {
			type: Object,
			default: () => ({direction: ORDER_BY.ASC, by: null}),
		},
		/**
		 * Параметры фильтрации
		 * @type {import('../UiForm/fields').FieldsArray}
		 */
		filters: {
			type: Array,
			default: () => [],
		},
		initialFilters: {
			type: Object,
			default: () => ({}),
		},
		tipsProviderKey: {
			type: String,
			default: undefined,
		},
		/** Дополнительные параметры запроса */
		mixData: {
			type: Object,
			default: null
		},
		public: {
			type: Boolean,
			default: false,
		},
		borderless: {
			type: Boolean,
			default: undefined,
		},
		/** Возможность экспорта в excel данных таблицы */
		canExport: {
			type: Boolean,
			default: true,
		},
		/** Что выводится при пустых items */
		emptyDataLabel: {
			type: String,
			default: 'Нет данных',
		},
		/** Скрыть таблицу */
		hideTable: {
			type: Boolean,
			default: false,
		},
		/** Начальный размер страницы */
		initialPageSize: {
			type: Number,
			default: undefined,
		},
		/** Boolean - флаг, который включает внутренние вотчеры для фильтров */
		customFilters: {
			type: Boolean,
			default: false,
		},
		/**
		 * Признак открываемости ряда, передается в UiTable
		 */
		touchable: {
			type: Boolean,
			default: false,
		},
		/**
		 * Признак показа кнопки скрытия таблицы
		 */
		showVisibilityToggle: {
			type: Boolean,
			default: false,
		},
		/**
		 * Признак показа таблицы в режиме lazyLoad (убирает возможность пагинации)
		 */
		lazyLoad: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const isTableVisible = this.getLocalStorageVisibility();

		const pref = convertToSnakeCase(this.tableGetter, '-').replace('-list-getter', '');
		const currentPageQueryKey = pref + '-current-page';
		const pageSizeQueryKey = pref + '-page-size';
		const sortDirectionQueryKey = pref + '-sort-direction';
		const sortByQueryKey = pref + '-sort-by';
		const filterQueryKey = pref + '-filter';

		let filterData = this.initialFilters;

		if (this.$route.query[filterQueryKey]) {
			filterData = JSON.parse(decodeURIComponent(this.$route.query[filterQueryKey])) || this.initialFilters;
		}

		return {
			currentPage: Number(this.$route.query[currentPageQueryKey] || 1),
			pageSize: Number(this.$route.query[pageSizeQueryKey] || this.initialPageSize || UI_TABLE_LIST_PAGE_SIZE_DEFAULT),
			filterData,
			sortDirection: this.$route.query[sortDirectionQueryKey] || this.initialSort.direction || ORDER_BY.ASC,
			sortBy: this.$route.query[sortByQueryKey] || this.initialSort.by || null,
			exportExcelName: '',
			isTableVisible,
			currentPageQueryKey,
			pageSizeQueryKey,
			sortDirectionQueryKey,
			sortByQueryKey,
			filterQueryKey
		}
	},

	computed: {
		pageSizeOptions() {
			return UI_TABLE_LIST_PAGE_SIZE_OPTIONS.map((value) => ({value, label: value}));
		},
		slotMethodsAndProps() {
			return {
				currentTable: this.currentTable,
				filters: this.filters,
				filterData: this.filterData,
				setFilterData: this.setFilterData,
				resetFilters: this.resetFilters,
				clearFilters: this.clearFilters,
				refetch: this.fetchTableData,
				setSortDirection: this.setSortDirection,
				setSortBy: this.setSortBy,
				sortDirection: this.sortDirection,
				sortBy: this.sortBy,
				totalCount: this.currentTable.totalCount,
				items: this.currentTable.items ?? [],
				isTableVisible: this.isTableVisible,
				hide: this.hide,
				show: this.show,
				toggleVisibility: this.toggleVisibility,
			};
		},
		/** Текущая таблица */
		currentTable() {
			return this.$store.getters[this.tableGetter];
		},
		totalCount() {
			return this.currentTable.totalCount;
		},
		skeletonStyles() {
			return {
				'margin': 'auto',
				'width': '80%',
				'min-width': '600px',
				'max-width': '1200px',
			}
		},
		perPageMinimum() {
			return UI_TABLE_LIST_PAGE_SIZE_OPTIONS[0];
		},
		isPaginationVisible() {
			return this.perPageMinimum <= this.totalCount && !this.lazyLoad;
		},
		isWatchMoreVisible() {
			return this.lazyLoad && (this.perPageMinimum <= this.totalCount && this.pageSize <= this.totalCount);
		},
		refetchDependencies() {
			if (this.lazyLoad) {
				return `${this.sortBy} ${this.sortDirection} ${this.public} ${this.tableAction}`;
			}
			return `${this.currentPage} ${this.pageSize} ${this.sortBy} ${this.sortDirection} ${this.public} ${this.tableAction}`;
		},
		isHeaderShown() {
			return this.tableTitle ||
				this.filters.length ||
				this.$slots.header ||
				this.$scopedSlots.header ||
				this.$slots['table-title'] ||
				this.$scopedSlots['table-title'] ||
				this.$slots['filters-append'] ||
				this.$scopedSlots['filters-append'];
		},
		sheets() {
			return [
				{
					label: this.tableTitle,
					columns: this.tableColumns,
					items: this.currentTable.items,
				}
			]
		},
		mediaForDesktopVal() {
			return this.mediaForDesktop.value;
		},
		tableTitleClasses() {
			return {
				'ui-table-list__title': true,
				'mb-22': this.filters.length
			}
		}
	},

	watch: {
		isTableVisible: {
			immediate: true,
			async handler() {
				this.setLocalStorageVisibility(this.isTableVisible);

				if (!this.$refs.toggleHeightTarget) {
					await this.$nextTick();
				}

				this.$refs.toggleHeightTarget?.toggleHeight(this.isTableVisible);
			},
		},
		filterData: {
			handler(newVal) {
				if (this.customFilters) {
					this.$emit('filtersChanged', newVal);
					this.currentPage = 1;
					this.fetchTableData();
				}
			},
			deep: true,
		},
		mixData: {
			handler() {
				this.fetchTableData();
			},
			deep: true,
		},
		initialFilters: {
			handler() {
				if (this.customFilters) {
					this.fetchTableData();
				}
			},
			deep: true,
		},
		tableGetter() {
			this.currentPage = 1;
			this.sortDirection = this.initialSort.direction ?? ORDER_BY.ASC;
			this.sortBy = this.initialSort.by ?? null;
		},
		refetchDependencies() {
			this.fetchTableData();
		},
	},

	beforeMount() {
		this.fetchTableData();
	},

	methods: {
		closeAppend() {
			this.$refs.table.closeAppend();
		},
		getRowStyle() {
			if (!this.whiteRowStyle) {
				return this.tableRowStyle;
			}
			return {backgroundColor: 'var(--white)'};
		},
		getLocalStorageKey() {
			return `isTableVisible::${this.$kc.username()}`;
		},
		setLocalStorageVisibility(value) {
			localStorage.setItem(this.getLocalStorageKey(), JSON.stringify({
				...JSON.parse(localStorage.getItem(this.getLocalStorageKey()) ?? '{}'),
				[this.tableGetter]: value,
			}));
		},
		getLocalStorageVisibility() {
			const ls = localStorage.getItem(this.getLocalStorageKey());
			if (!ls) {
				return true;
			}
			if (!this.showVisibilityToggle) {
				return true;
			}
			return JSON.parse(ls)[this.tableGetter] ?? true;
		},
		watchMore() {
			this.pageSize = this.pageSize + this.initialPageSize;
			this.fetchTableData(true);
		},
		hide() {
			this.isTableVisible = false;
		},
		show() {
			this.isTableVisible = true;
		},
		toggleVisibility() {
			this.isTableVisible = !this.isTableVisible;
		},
		setSortDirection(val) {
			this.sortDirection = val;
		},
		setSortBy(val) {
			this.sortBy = val;
		},
		resetFilters() {
			this.filterData = this.initialFilters;
		},
		setFilterData(filters) {
			this.filterData = filters;
			this.fetchTableData();
		},
		clearFilters() {
			this.filterData = {};
		},
		changeExcelName(val) {
			this.exportExcelName = val;
		},
		filtersChanged() {
			this.currentPage = 1;
		},
		fetchTableData(lazyLoader = false) {
			if (this.enabledUrlQuery) {
				const url = new URL(window.location.href);
				url.searchParams.set(this.currentPageQueryKey, String(this.currentPage));
				url.searchParams.set(this.pageSizeQueryKey, String(this.pageSize));
				url.searchParams.set(this.sortDirectionQueryKey, this.sortDirection || '');
				url.searchParams.set(this.sortByQueryKey, this.sortBy || '');
				url.searchParams.set(this.filterQueryKey, Object.keys(this.filterData).length
					? encodeURIComponent(JSON.stringify(this.filterData)) : '');

				window.history.replaceState({}, '', url.toString());
			}

			const data = {
				currentPage: this.currentPage,
				pageSize: this.pageSize,
				filters: this.filterData,
				mixData: this.mixData,
				sortBy: this.sortBy,
				sortDirection: this.sortDirection,
				public: this.public,
				lazyLoader,
			};

			this.$store.dispatch(this.tableAction, {data});
		},
		updatePageIndex() {
			const lastAvailablePageIndex = Math.floor(this.totalCount / this.pageSize) + (this.totalCount % this.pageSize > 0 ? 1 : 0);
			this.currentPage = Math.min(lastAvailablePageIndex, this.currentPage);
		}
	}
}
</script>
