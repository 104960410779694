import {
	getApiRequestBuilder,
	postApiRequestBuilder,
	putApiRequestBuilder
} from '@utils/RequestBuilder';

const END_POINT_BASE = 'participants/moderate';

export async function getParticipants(pageNumber, pageSize, status) {
	let queryParams = `pageNumber=${pageNumber}&pageSize=${pageSize}`;
	if (status) {
		queryParams += `&status=${status}`;
	}
	return getApiRequestBuilder(`${END_POINT_BASE}?${queryParams}`).send();
}

export async function getParticipantProfile(participantId) {
	return getApiRequestBuilder(`participants/${participantId}/moderate`).send();
}

export async function getParticipantRating(participantId) {
	return getApiRequestBuilder(`moderate/participants/${participantId}/rating`).send();
}

export async function approveParticipant({ participantId, qualification, rating, loanLimit }) {
	const data = {};
	if (qualification != null) {data.qualification = qualification};
	if (rating != null) {data.rating = rating};
	if (loanLimit != null) {data.loanLimit = loanLimit};
	return postApiRequestBuilder(`moderate/participants/${participantId}/approve`).send(data);
}

export async function rejectParticipant({ participantId, reason }) {
	return postApiRequestBuilder(`moderate/participants/${participantId}/reject`).send({ reason });
}

export async function setParticipantLoanLimit(participantId, loanLimit) {
	return putApiRequestBuilder(`operator/participants/${participantId}/loan-limit`).send({ loanLimit });
}

export async function activateParticipant({ participantId, form115Risk = undefined }) {
	const data = {};
	if (form115Risk != undefined) data.form115Risk = form115Risk;
	return postApiRequestBuilder(`moderate/participants/${participantId}/activate`).send(data);
}

export async function admitParticipant({ participantId }) {
	const data = {};
	return postApiRequestBuilder(`moderate/participants/${participantId}/admit`).send(data);
}

export async function getForm115History(participantId) {
	return getApiRequestBuilder(`moderate/participants/${participantId}/form115/history`)
		.send()
		.then(res => res.data.items);
}
