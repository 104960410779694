import { getApiRequestBuilder } from '@utils/RequestBuilder';
import { upperCaseFirst } from '@/utils/common';
import { copyObj } from '@/utils/common';
import { INVEST_TYPES } from '@/configs/investProject';
import Vue from 'vue';

/**
 * Названия actions и getters
 *
 * contractInvestmentListAction, contractInvestmentListGetter
 * contractPlatformListAction, contractPlatformListGetter
 * adminUserListListAction, adminUserListListGetter
 * operatorNbkiSendListAction, operatorNbkiSendListGetter
 * certificateEntityListAction, certificateEntityListGetter
 * certificateUserListAction, certificateUserListGetter
 * projectsPublicListAction, projectsPublicListGetter
 * projectsPublicReleasedListAction, projectsPublicReleasedListGetter
 * projectsModerateListAction, projectsModerateListGetter
 * pitchesPublicListAction, pitchesPublicListGetter
 * pitchesModerateListAction, pitchesModerateListGetter
 * participantsFinanceOperationsListAction, participantsFinanceOperationsListGetter
 * participantsFinanceLoansListAction, participantsFinanceLoansListGetter
 * moderateFinanceHistoryAccountsNominalListAction, moderateFinanceHistoryAccountsNominalListGetter
 * projectsMineListAction, projectsMineListGetter
 * pitchesMineListAction, pitchesMineListGetter
 * ucpListGetter, ucpListAction
 * userInvestmentsUCPListGetter, userInvestmentsUCPListAction
 * userInvestmentsSTOCKListGetter, userInvestmentsSTOCKListAction
 * userInvestmentsLOANListGetter, userInvestmentsLOANListAction
 * userInvestmentsCONVERTIBLE_LOANListGetter, userInvestmentsCONVERTIBLE_LOANListAction
 * adminConvLoanRejectListListAction, adminConvLoanRejectListListGetter
 * convLoanConversionProgressListAction, convLoanConversionProgressListGetter
 * projectsLoanTurnoverListAction, projectsLoanTurnoverListGetter
 * projectsUcpTurnoverListAction, projectsUcpTurnoverListGetter
 * adminIncomingTransactionsListAction, adminIncomingTransactionsListGetter
 * adminFinanceAccountsReconciliationListAction, adminFinanceAccountsReconciliationListGetter
 * participantsFinanceInvoiceDetailsListAction, participantsFinanceInvoiceDetailsListGetter
 */

/** Массив с url's списков */
const urls = [
	'contract-investment/{type}',
	'contract-investment/request',
	'contract-platform/{type}',
	'admin/user/list',
	'operator/nbki/send',
	'certificate/entity/{entityId}',
	'certificate/user',
	`user/investments`,
	`user/investments/${INVEST_TYPES.UCP}`,
	`user/investments/${INVEST_TYPES.STOCK}`,
	`user/investments/${INVEST_TYPES.LOAN}`,
	`user/investments/${INVEST_TYPES.CONVERTIBLE_LOAN}`,
	`user/notifications`,
	`user/notifications/platform`,
	'projects/moderate',
	'pitches/moderate',
	'participants/moderate',
	'moderate/participants/{participantId}/form115/history',
	'moderate/participants/{participantId}/contracts',
	'moderate/participants/{participantId}/projects',
	`moderate/participants/{participantId}/investments`,
	`moderate/participants/{participantId}/investments/${INVEST_TYPES.UCP}`,
	`moderate/participants/{participantId}/investments/${INVEST_TYPES.STOCK}`,
	`moderate/participants/{participantId}/investments/${INVEST_TYPES.LOAN}`,
	`moderate/participants/{participantId}/investments/${INVEST_TYPES.CONVERTIBLE_LOAN}`,
	'projects',
	'projects/public',
	'projects/public/released',
	'projects/mine',
	'projects/mine/news',
	'projects/mine/discussions',
	'projects/{projectId}/investors',
	'projects/{projectId}/crediting-shares',
	'pitches',
	'pitches/public',
	'pitches/mine',
	'participants/{participantId}/finance/operations',
	'admin/finance/history/accounts/nominal',
	'admin/participants',
	'admin/projects',
	'ucp',
	'ucp/{token}/history',
	'participants/{participantId}/finance/loans',
	'contract-platform/finance/{role}',
	'projects/{projectId}/news',
	'subscriptions/projects',
	'projects/{projectId}/discussions',
	'projects/{projectId}/investors-summary',
	'projects/{projectId}/payment-schedule/investments/{investmentId}',
	'projects/{projectId}/news/{newsId}/comments',
	'projects/{projectId}/discussions/{discussionId}/comments',
	'platform-news',
	'operator/user/list',
	'operator/participants/withdrawal',
	'platform-questions',
	'platform-promotions/operator',
	'statistics/users',
	'statistics/projects',
	'statistics/obligations',
	'admin/conv-loan/reject-list',
	'conv-loan/{projectId}/progress',
	'projects/{projectId}/loan-turnover',
	'projects/{projectId}/ucp-turnover',
	'admin/incoming-transactions',
	'admin/finance/accounts/reconciliation',
	'participants/finance/invoice-details',
	'entity/patch/moderate',
	'operator/participants/{participantId}/loan-limit',
];

function getListName(url) {
	let newUrls = url.split(/[/-]/g);
	newUrls = newUrls.filter(word => {
		if (word.startsWith('{') && word.endsWith('}')) {
			return false;
		}
		return true;
	})
	return newUrls.map((word, index) => {
		return index === 0 ? word : upperCaseFirst(word);
	}).join('') + 'List';
}


function defineLists() {
	return Object.fromEntries(
		urls.map((url) => [getListName(url), { loader: true }]),
	);
}

function generateActions() {
	let actions = {};
	urls.forEach((url) => {
		const listName = getListName(url);
		actions[`${listName}Action`] = async ({ commit, state }, { data }) => {
			let list = state.__lists[listName];

			let copyData = copyObj(data);
			list = { ...list, ...copyData };
			/** Устанавливаем loader */
			commit(`${listName}Mutation`, { data: { ...list, loader: !copyData.lazyLoader, lazyLoader: copyData.lazyLoader} });
			let queryParams = `pageNumber=${list.currentPage}&pageSize=${list.pageSize}`;
			/** Подмешиваем сортировки и mixData в запрос */
			if (copyData.filters && Object.keys(copyData.filters).length > 0) {
				Object.entries(copyData.filters).filter(([_, value]) => (value !== null && value !== undefined && value)).forEach(([key, value]) => {
					let keyData = key,
						valueData = value;
					queryParams += `&${keyData}=${valueData}`;
				});
			}
			let requestUrl = url;
			const filteredMixData = copyObj(copyData.mixData);
			const urlParams = url.match(/\{(.*?)\}/g);
			if (urlParams && !(filteredMixData && Object.keys(filteredMixData).length > 0)) {
				console.error(`mixData не содержит необходимых параметров для формирования запроса: ${url}`);
			}
			if (filteredMixData && Object.keys(filteredMixData).length > 0) {
				if (urlParams) {
					urlParams.forEach(param => {
						const paramKey = param.slice(1, -1);
						if (filteredMixData[paramKey]) {
							/** Подмешиваем динамический параметр из mixData если он имеется, если нет - кидаем ошибку */
							requestUrl = requestUrl.replace(param, filteredMixData[paramKey]);
							delete filteredMixData[paramKey];
						} else {
							console.error(`Параметр ${paramKey} отсутствует в mixData`);
						}
					});
				}
				const mixedData = Object.entries(filteredMixData);
				mixedData.forEach(([key, value]) => {
					const params = new URLSearchParams();
					if (Array.isArray(value)) {
						value.forEach((item) => {
							params.append(key, item);
						});
					} else {
						params.set(key, value);
					}
			    queryParams += '&' + params.toString();
				});
			}
			if (copyData.sortBy) {
				let newSortBy = copyData.sortBy;
				const splitedSortings = copyData.sortBy.split('.');
				if (splitedSortings.length > 1) newSortBy = splitedSortings[splitedSortings.length - 1];
				queryParams += `&sortBy=${newSortBy}`;
			}
			if (copyData.sortDirection) {
				queryParams += `&sortDirection=${copyData.sortDirection}`;
			}
			const request = getApiRequestBuilder(`${requestUrl}?${queryParams}`)
			if (copyData.public) {
				request.public;
			}
			request.send()
				.then((res) => {
					/** Передаем значения в определенный mutation и снимаем loader */
					commit(`${listName}Mutation`, {
						data: {
							...list,
							...res.data,
							loader: false,
							lazyLoader: false,
						}
					});
				})
				.finally(() => {
					commit(`${listName}Mutation`, {
						data: {
							...state.__lists[listName],
							loader: false,
							lazyLoader: false,
						}
					});
				});
		};
	});
	return actions;
}

function generateGetters() {
	let getters = {};
	urls.forEach((url) => {
		const listName = getListName(url);
		getters[`${listName}Getter`] = (state) =>
			state.__lists[listName];
	});
	return getters;
}

function generateMutations() {
	let mutations = {};
	urls.forEach((url) => {
		const listName = getListName(url);
		mutations[`${listName}Mutation`] = (state, { data }) => {
			Vue.set(state.__lists, listName, data);
		};
	});
	return mutations;
}

export default {
	actions: {
		...generateActions(),
	},
	state: {
		__lists: defineLists(),
	},
	mutations: {
		...generateMutations(),
	},
	getters: {
		listsDefinitionMap: (state) => state.__lists,

		...generateGetters(),
	},
};
