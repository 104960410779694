
import store from "../index";

export default {
	namespaced: true,
	state() {
		return {
			
		};
	},

	mutations: {
		setNewValidationRules(state, newRules) {
			state.validationRules = {...state.validationRules, newRules};
		},
	},

	getters: {
		/* 
		* Объект с данными для валидации/информации, getDictValue получает три аргумента (Название справочника, поле в справочнике по которому фильтровать, 
		значение в этом поле)
		* Прим. getDictValue('projectTypes', 'type', 'STOCK')
		* Добавлять новые правила лучше в конце списка каждого поля, по аналогии
		*/
		validationRules: () => ({
			common: {
				maxInvest: getDictValue('commonSettings', 'code', 'MAX_NOQUAL_INVEST')?.value,
				tinkoffSandbox: getDictValue('commonSettings', 'code', 'TINKOFF_SANDBOX_SETTING')?.value,
				minSlidesProjectPresentation: Number(getDictValue('commonSettings', 'code', 'MIN_SLIDES_PROJECT_PRESENTATION')?.value),
				maxSlidesProjectPresentation: Number(getDictValue('commonSettings', 'code', 'MAX_SLIDES_PROJECT_PRESENTATION')?.value),
				minSlidesDraftProjectPresentation: Number(getDictValue('commonSettings', 'code', 'MIN_SLIDES_DRAFT_PROJECT_PRESENTATION')?.value),
				maxSlidesDraftProjectPresentation: Number(getDictValue('commonSettings', 'code', 'MAX_SLIDES_DRAFT_PROJECT_PRESENTATION')?.value),
			},
			stock: {
				minInvest: getDictValue('projectTypes', 'type', 'STOCK')?.minInvest,
				maxInvest: getDictValue('projectTypes', 'type', 'STOCK')?.maxInvest,
				maxTargetLPI: getDictValue('projectTypes', 'type', 'STOCK')?.maxLoan,
				minTargetLPI: getDictValue('projectTypes', 'type', 'STOCK')?.minLoan,
				minProjectTerm: getDictValue('publishTerm', 'type', 'STOCK')?.minDays,
				maxProjectTerm: getDictValue('publishTerm', 'type', 'STOCK')?.maxDays,
			},
			loan: {
				minInvest: getDictValue('projectTypes', 'type', 'LOAN')?.minInvest,
				maxInvest: getDictValue('projectTypes', 'type', 'LOAN')?.maxInvest,
				maxTargetLPI: getDictValue('projectTypes', 'type', 'LOAN')?.maxLoan,
				minTargetLPI: getDictValue('projectTypes', 'type', 'LOAN')?.minLoan,
				minProjectTerm: getDictValue('publishTerm', 'type', 'LOAN')?.minDays,
				maxProjectTerm: getDictValue('publishTerm', 'type', 'LOAN')?.maxDays,
				loanTermMin: getDictValue('platformLoanLimits', 'code', "LOAN_TERM")?.minValue,
				loanTermMax: getDictValue('platformLoanLimits', 'code', "LOAN_TERM")?.maxValue,
				loanTermForEndOfTermPaymentMin: getDictValue('platformLoanLimits', 'code', "LOAN_TERM_FOR_END_OF_TERM_PAYMENT")?.minValue,
				loanTermForEndOfTermPaymentMax: getDictValue('platformLoanLimits', 'code', "LOAN_TERM_FOR_END_OF_TERM_PAYMENT")?.maxValue,
				borrowingRateMax: getDictValue('platformLoanLimits', 'code', 'BORROWING_RATE')?.maxValue,
				borrowingRateMin: getDictValue('platformLoanLimits', 'code', 'BORROWING_RATE')?.minValue,
			},
			convertible_loan: {
				minInvest: getDictValue('projectTypes', 'type', 'CONVERTIBLE_LOAN')?.minInvest,
				maxInvest: getDictValue('projectTypes', 'type', 'CONVERTIBLE_LOAN')?.maxInvest,
				maxTargetLPI: getDictValue('projectTypes', 'type', 'CONVERTIBLE_LOAN')?.maxLoan,
				minTargetLPI: getDictValue('projectTypes', 'type', 'CONVERTIBLE_LOAN')?.minLoan,
				minProjectTerm: getDictValue('publishTerm', 'type', 'CONVERTIBLE_LOAN')?.minDays,
				maxProjectTerm: getDictValue('publishTerm', 'type', 'CONVERTIBLE_LOAN')?.maxDays,
				borrowingRateMax: getDictValue('platformConvLoanLimits', 'code', 'BORROWING_RATE')?.maxValue,
				borrowingRateMin: getDictValue('platformConvLoanLimits', 'code', 'BORROWING_RATE')?.minValue,
				conversionPeriod: getDictValue('commonSettings', 'code', 'CONV_LOAN_CONVERSION_PERIOD')?.value,
			},
			ucp: {
				minInvest: getDictValue('projectTypes', 'type', 'UCP')?.minInvest,
				maxInvest: getDictValue('projectTypes', 'type', 'UCP')?.maxInvest,
				maxTargetLPI: getDictValue('projectTypes', 'type', 'UCP')?.maxLoan,
				minTargetLPI: getDictValue('projectTypes', 'type', 'UCP')?.minLoan,
				minProjectTerm: getDictValue('publishTerm', 'type', 'UCP')?.minDays,
				maxProjectTerm: getDictValue('publishTerm', 'type', 'UCP')?.maxDays,
			},
			pitch: {
				minPitchTerm: getDictValue('publishTerm', 'type', 'PITCH')?.minDays,
				maxPitchTerm: getDictValue('publishTerm', 'type', 'PITCH')?.maxDays,
			}
		})
	}
}

function getDictValue(dictName, filterProperty, propertyName) {
	return store.getters[`dictionary/${dictName}`]?.find((item) => item[filterProperty] === `${propertyName}`)
}
