import { getApiRequestBuilder, postApiRequestBuilder } from '@/utils/RequestBuilder';


export function getInterestRate(participantId, term = 0, paymentSchemeCode = "ANNUITY_PAYMENT", rateTypeCode = "FIXED", paymentDelayCode = "NONE", collateralTypeCode = []) {
	return postApiRequestBuilder('projects/interest-rate')
		.send({
			participantId: participantId,
			term: term,
			paymentType: paymentSchemeCode,
			rateType: rateTypeCode,
			paymentDelayCode: paymentDelayCode,
			collateralTypeCode: collateralTypeCode,
		});
}

export function getLoanCalculate(paymentScheduleParams) {
	return postApiRequestBuilder('projects/payment-schedule')
		.send(paymentScheduleParams);
}

export function getLoanPaymentSchedule(projectId) {
	return getApiRequestBuilder(`projects/${projectId}/payment-schedule`)
		.send();
}
