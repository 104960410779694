var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ui-sidebar custom-scrollbar"
  }, [_c('UiSkeleton', {
    attrs: {
      "loading": _vm.loading,
      "show-mode": "if"
    }
  }, [_vm.options.length && _vm.showDefaultSidebar ? _c('ul', {
    staticClass: "ui-sidebar__list"
  }, _vm._l(_vm.options, function (option, index) {
    return _c('li', {
      key: index,
      staticClass: "ui-sidebar__list-item"
    }, [_c('button', {
      class: {
        'ui-sidebar__list-link': true,
        'ui-sidebar__list-link--selected': option[_vm.selectedKey] === _vm.selectedOption[_vm.selectedKey],
        'ui-sidebar__list-link--selected__grey': _vm.tabActiveGrey && option[_vm.selectedKey] === _vm.selectedOption[_vm.selectedKey],
        'ui-sidebar__list-link__grey': _vm.tabActiveGrey,
        'chatted': _vm.chatStyle
      },
      on: {
        "click": function ($event) {
          return _vm.changeOption(option);
        }
      }
    }, [_vm._t("label", function () {
      var _vm$formatDate;
      return [_vm.showDate ? _c('div', {
        staticClass: "ui-sidebar__link-date"
      }, [_vm._v(" " + _vm._s((_vm$formatDate = _vm.formatDate(option.created, "DD.MM.YYYY")) !== null && _vm$formatDate !== void 0 ? _vm$formatDate : '') + " ")]) : _vm._e(), _vm._v(" " + _vm._s(option.name) + " "), _vm.iconsData[option.status] ? _c('UiSmartIcon', {
        staticClass: "ui-sidebar__link-img",
        attrs: {
          "name": _vm.iconsData[option.status].name,
          "color": option[_vm.selectedKey] === _vm.selectedOption[_vm.selectedKey] ? '#fff' : _vm.iconsData[option.status].color,
          "size": 20
        }
      }) : _vm._e()];
    }, null, {
      option: option,
      active: option[_vm.selectedKey] === _vm.selectedOption[_vm.selectedKey],
      iconsData: _vm.iconsData
    })], 2)]);
  }), 0) : _c('ul', {
    staticClass: "ui-sidebar__list--mobile"
  }, _vm._l(_vm.options, function (option, index) {
    return _c('li', {
      key: index,
      staticClass: "ui-sidebar__mobile-item"
    }, [_vm._t("mobile-item", null, null, {
      option: option,
      active: option[_vm.selectedKey] === _vm.selectedOption[_vm.selectedKey],
      iconsData: _vm.iconsData
    })], 2);
  }), 0), !_vm.options.length ? _c('div', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(_vm.noOptionsTitle))]) : _vm._e()]), _vm.mediaForDesktop.value ? _vm._t("bottom") : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }