import { getNoun } from './common';

const monthArr = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
const weekDayArr = ["Воскресение", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
const wedArr = ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"];
const wdArr = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
const pattArr = ["ms", "YYYY", "YY", "MONTH", "MON", "MM", "M", "WEEKDAY", "WED", "WD", "W", "DDD", "DD", "D", "hh", "h", "nn", "n", "ss", "s", "ZZ"];
const pattRE = new RegExp("(" + pattArr.join("|") + ")", "ig");

export const FORMAT_EXPORT_CODE = {
	GENERAL: "0",
	PERCENT: "0.00%",
	USD: "0.00 $",
	EUR: "EUR",
	RUB: "0.00 ₽"
}

export const CURRENCY_CODE = {
	USD: "USD",
	EUR: "EUR",
	RUB: "RUB"
}

export const CURRENCY_DISPLAY = {
	SYMBOL: "symbol",
	CODE: "code",
	NAME: "name"
}

/**
 * Форматирование даты по шаблону
 * 
 * @param {Date} dt Дата
 * @param {string} fmt Формат вывода даты.
 * @returns {string}
 * 		Используются подстановочные символы (регистр не важен):
 *			MS - миллисекунды, в формате 000
 *			S, SS - секунды; здесь и далее: X - без лидирующих нулей, XX - с лидирующим нулем, ровно 2 цифры
 *			N, NN - минуты
 *			H, HH - часы
 *			D, DD - день (число) месяца
 *			M, MM - номер месяца
 *			MONTH - полное наименование месяца (с заглавной буквы)
 *			MON - сокращенное наименование месяца (первые 3 буквы полного наименования)
 *			YY - последние 2 цифры года
 *			YYYY - год целиком
 *			DDD - номер дня с начала года
 *			W - номер дня недели (1 - 7 => пнд - вск)
 *			WEEKDAY - полное название дня недели (с заглавной буквы)
 *			WED - краткое 3х-буквенное название дня недели
 *			WD - краткое 2х-буквенное название дня недели
 *			ZZ - смещение часового пояса относительно UTC в формате ±00:00
 */
export function formatDate(dt, fmt) {
	if (!dt) return undefined;
	if (!(dt instanceof Date)) {
		if (/^(number|string)$/.test(typeof (dt))) {
			dt = new Date(dt);
		} else if (typeof (dt) === "object") {
			if (dt.seconds && dt.nanos) { // java.util.Instant
				dt = new Date(dt.seconds * 1000 + Math.trunc(dt.nanos / 10e6));
			}
		}
	}
	
	const zz = getPrettyTimeZone(dt);
	const yyyy = dt.getFullYear();
	const mon = dt.getMonth() + 1;
	const day = dt.getDate();
	const wd = dt.getDay();
	const h = dt.getHours();
	const m = dt.getMinutes();
	const s = dt.getSeconds();
	const ms = dt.getMilliseconds();
	let res = fmt;
	const matches = res.match(pattRE);
	if (matches) {
		matches.forEach(mm => {
			switch (mm.toUpperCase()) {
			case "YYYY": res = res.replace(mm, yyyy.toString()); break;
			case "YY": res = res.replace(mm, yyyy.toString().slice(-2)); break;
			case "MM": res = res.replace(mm, mon.toString().padStart(2, "0")); break;
			case "M": res = res.replace(mm, mon.toString()); break;
			case "MON": res = res.replace(mm, tuneCase(monthArr[mon - 1].substring(0, 3), mm)); break;
			case "MONTH": res = res.replace(mm, tuneCase(monthArr[mon - 1], mm)); break;
			case "DDD": {
				const dt_ = new Date(dt.getFullYear().toString() + "-01-01");
				res = res.replace(mm, (Math.trunc((dt - dt_) / 86400000) + 1).toString().padStart(3, "0"));
				break;
			}
			case "DD": res = res.replace(mm, day.toString().padStart(2, "0")); break;
			case "D": res = res.replace(mm, day.toString()); break;
			case "W": res = res.replace(mm, wd === 0 ? "7" : wd.toString()); break;
			case "WD": res = res.replace(mm, tuneCase(wdArr[wd], mm)); break;
			case "WED": res = res.replace(mm, tuneCase(wedArr[wd], mm)); break;
			case "WEEKDAY": res = res.replace(mm, tuneCase(weekDayArr[wd], mm)); break;
			case "HH": res = res.replace(mm, h.toString().padStart(2, "0")); break;
			case "H": res = res.replace(mm, h.toString()); break;
			case "NN": res = res.replace(mm, m.toString().padStart(2, "0")); break;
			case "N": res = res.replace(mm, m.toString()); break;
			case "SS": res = res.replace(mm, s.toString().padStart(2, "0")); break;
			case "S": res = res.replace(mm, s.toString()); break;
			case "MS": res = res.replace(mm, ms.toString().padStart(3, "0")); break;
			case "ZZ": res = res.replace(mm, zz.toString().padStart(3, "0")); break;
			}
		});
	}
	return res;
}

/**
 * Форматирует число в виде строки по заданным параметрам
 * @param num Число
 * @param precision Точность. Количество цифр после запятой
 * @param grouping Группировка. Признак разделения групп разрядов (тысяч). По умолчанию: false.
 */
export function formatNum(num, precision, grouping = false) {
	const opt = {
		useGrouping: grouping,
		minimumFractionDigits: precision,
		maximumFractionDigits: precision,
	};
	return Intl.NumberFormat("ru-RU", opt).format(num);
}

/**
 * Форматирует сумму в виде строки в денежном формате
 * @param amount Сумма
 * @param currencyCode Код валюты ("USD", "EUR", "RUB", ...)
 * @param currencyDisplay Вариант отображения валюты ("symbol" - символ валюты, например $; "code" - код валюты; "name" - наименование валюты). По умолчанию: "symbol".
 * @param precision Число знаков после запятой. По умолчанию - 2.
 */
export function formatMoney(amount, currencyCode = undefined, currencyDisplay = CURRENCY_DISPLAY.SYMBOL, precision = 2) {
	if ((!amount && amount !== 0) || typeof amount !== 'number') return "";
	if (currencyCode) {
		const opt = {
			style: "currency",
			currency: currencyCode,
			currencyDisplay,
			minimumFractionDigits : precision
		};
		const fmt = new Intl.NumberFormat("ru-RU", opt);
		return fmt.format(amount);
	}
	return formatNum(amount, precision, true);
}

/**
 * Форматирует диапазон сумм в виде строки в денежном формате
 * @param {number} amountFrom Сумма
 * @param {number} amountTo Сумма
 * @param {'dash' | 'text'} type Сумма
 * @param {number} precision Число знаков после запятой. По умолчанию - 2.
 * @param {string} currencyCode Код валюты ("USD", "EUR", "RUB", ...)
 */
export function formatMoneyRange(amountFrom, amountTo, type = 'dash', precision = 0, currencyCode = 'RUB') {
	let range
	if (type === 'dash') {
		range = `${formatNum(amountFrom, precision, true)} - ${formatNum(amountTo, precision, true)}`;
	} else if (type === 'text') {
		range = `от ${formatNum(amountFrom, precision, true)} до ${formatNum(amountTo, precision, true)}`;
	}
	
	if (!currencyCode) {
		return range
	}

	return `${range} ${getCurrencySymbol(currencyCode)}`
}

/**
 * Форматирует диапазон сумм в виде строки в денежном формате
 * @param {number} amountFrom Сумма
 * @param {number} amountTo Сумма
 * @param {number} precision Число знаков после запятой. По умолчанию - 2.
 * @param {string} currencyCode Код валюты ("USD", "EUR", "RUB", ...)
 */
export function formatMoneyRangeText(amountFrom, amountTo , precision = 0, currencyCode = 'RUB') {
	return `от ${formatNum(amountFrom, precision, true)} до ${formatMoney(amountTo, currencyCode, CURRENCY_DISPLAY.SYMBOL, precision)}`;
}

/**
 * Получить символ валюты
 * @param {string} currency Код валюты ("USD", "EUR", "RUB", ...)
 */
export const getCurrencySymbol = (currency) => (0).toLocaleString('ru-RU', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim()

/**
 * Форматирует номер телефона в формат +# (###) ###-##-##
 * @param {string} phone номер телефона
 */
export function formatPhone(phone) {
	const n = keepNumbers(phone);
	if (n.length !== 11) {
		console.warn('formatPhone: not supported: phone number is not 11 digits');
		return;
	}
	return `+${n[0]} (${n.slice(1, 4)}) ${n.slice(4, 7)}-${n.slice(7, 9)}-${n.slice(9, 11)}`;
}

/**
 * оставить только цифры в строке
 * @param {string} str
 */
export function keepNumbers(str) {
	return str.replace(/\D/g, '');
}

/**
 * Возвращается строка, с корректной обработкой null, с возможностью добавить разделители и скобки
 * 
 * @param val Значение (строка) для вывода
 * @param pattern Необязательное. Шаблон вывода строки, если выполнено условие. Для подстановки строки используется символ '?'. Умолчательно - "?" (только сама строка).
 * 		Примеры: ", ?"; " (?)"...
 * @param cond Необязательное. Условие вывода (логика или функция-предикат), если не выполнено, вернется пустая строка. Умолчательно - не пустое значение
 */
export function print(val, pattern = "?", cond = null) {
	let flag = !!val;
	if (cond) {
		if (typeof (cond) === "function") {
			flag = cond(val);
		} else {
			flag = !!cond;
		}
	}
	if (flag) {
		const str = "" + val;
		if (pattern) {
			return pattern.replace("?", str)
		} else {
			return str;
		}
	}
	return "";
}

/**
 * Возвращает строку, с размером в подходящих единицах (Кб, Мб, ...) по переданному числу байт.
 *
 * @param bytes Размер в байтах
 * @return Строка с размером в соответствующих единицах (вида "14.72 Кб")
 */
export function bytesStr(bytes) {
	if (bytes < (1 << 13)) // до 8Kb
		return bytes.toString() + " б";
	else if (bytes < (1 << 23)) // до 8Mb
		return (bytes >>> 10).toFixed(0) + " Кб";
	else if (bytes < (1 << 30) * 2) // до 2Gb
		return (bytes / (1 << 20)).toFixed(2) + " Мб";
	else
		return (bytes / (1 << 30)).toFixed(2) + " Гб";
}

export function bytesStrOneStep(bytes) {
	if (bytes < 1024)
		return bytes + " б";

	if (bytes < 1024 * 1024) {
		const kb = Math.floor(bytes / 1024);
		const b = bytes % 1024;
		return b ? `${kb} Кб ${b} б` : `${kb} Кб`;
	}

	if (bytes < 1024 * 1024 * 1024) {
		const mb = Math.floor(bytes / (1024 * 1024));
		const kb = Math.floor((bytes % (1024 * 1024)) / 1024);
		return kb ? `${mb} Мб ${kb} Кб` : `${mb} Мб`;
	}

	const gb = Math.floor(bytes / (1024 * 1024 * 1024));
	const mb = Math.floor((bytes % (1024 * 1024 * 1024)) / (1024 * 1024));
	return mb ? `${gb} Гб ${mb} Мб` : `${gb} Гб`;
}

/**
 * Оставшееся кол-во дней до даты
 *
 * @param {Date} target
 * @returns {string}
 */
export function daysRemaining(target) {
	const days = (target.getTime() - Date.now()) / 8.64e+7 | 0;
	return formatDays(days);
}

/**
 * Строка вида "1 день", "2 дня", "5 дней"
 * 
 * @param {number} days 
 * @returns {string}
 */
export function formatDays(days, one = 'день', two = 'дня', five = 'дней') {
	return `${days} ${getNoun(days, one, two, five)} `;
}

export function formatBool(bool) {
	return ((bool === true) ? 'Да' : 'Нет') ?? 'Нет данных';
}

/**
 * @param {string} word
 * @param {string} reference
 */
function tuneCase(targetWord, referenceWord) {
	let result = '';
	for (let i = 0; i < targetWord.length; i++) {
		const targetChar = targetWord.charAt(i);
		const referenceChar = referenceWord.charAt(Math.min(i, referenceWord.length - 1));
		if (referenceChar.toLowerCase() === referenceChar) {
			result += targetChar.toLowerCase();
		} else if (referenceChar.toUpperCase() === referenceChar) {
			result += targetChar.toUpperCase();
		} else {
			result += targetChar;
		}
	}
	return result;
}

/**
 * @param {Date} dt
 * @returns {string} Часовая зона в формате "+03:00"
 */
function getPrettyTimeZone(dt) {
	const zz = dt.getTimezoneOffset();
	const offset = -zz;
	const sign = offset >= 0 ? '+' : '-';
	const hours = Math.floor(Math.abs(offset) / 60);
	const minutes = Math.abs(offset) % 60;
	return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function convertToSnakeCase(string, pattern = '_') {
	return string.replace(/[A-Z]/g, (match) => pattern + match.toLowerCase())
		.replace(/^_/, '');
}